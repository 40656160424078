import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import useApi from "../../services/auth/useApi";
import { CalendarBlockType } from "../../types/block/BlockType";
import { useJuneTrackCall } from "../../utils/june/analytics";
import { useSetRecoilState } from "recoil";
import { mobaCalendarListState } from "../../recoil/calendar/mobaCalendarListState";
import { convertClientBlockTypeToServerBlockType } from "../../utils/common/block/convertClientBlockTypeToServerBlockType";
import { deleteNullValueInObject } from "../../utils/taskDetail/formatServerSendData";

export const updateCalendarBlockApi = async (
  api: ReturnType<typeof useApi>,
  payload: CalendarBlockType
): Promise<void> => {
  const serverConvertedBlock = convertClientBlockTypeToServerBlockType(payload);
  const nullDeletedData = deleteNullValueInObject(serverConvertedBlock);

  await api.patch(
    `/tasks/${nullDeletedData.id}?creator=${nullDeletedData.creator}`,
    nullDeletedData,
    {
      headers: {
        "X-Requester": nullDeletedData.creator,
      },
    }
  );
};

interface UpdateCalendarBlockMutationOptions {
  payload: CalendarBlockType;
}

export const useUpdateCalendarBlockMutation = (
  options?: UseMutationOptions<void, AxiosError, UpdateCalendarBlockMutationOptions>
) => {
  const api = useApi();
  const trackCall = useJuneTrackCall();
  const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);

  return useMutation({
    mutationFn: ({ payload }: UpdateCalendarBlockMutationOptions) =>
      updateCalendarBlockApi(api, payload),

    onMutate: async (variables: UpdateCalendarBlockMutationOptions) => {
      const { payload } = variables;

      setMobaCalendarList((prev: CalendarBlockType[] | null) => {
        if (!prev) return [];

        return prev.map((task) => (task.id === payload.id ? payload : task));
      });
    },

    onSuccess: () => {
      trackCall("update_block", { location: "inbox" });
    },
    onError: (error) => {
      console.error("error", error);
    },
    ...options,
  });
};

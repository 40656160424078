import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { v4 as uuid4 } from "uuid";
import { useDrop } from "react-dnd";
import { useQueryClient } from "@tanstack/react-query";
import {
  calendarLastMonthSelector,
  draggedEventState,
} from "../../../recoil/calendar/calendarStateV2";
import { INPROGRESS } from "../../../constants/taskStateType";
import useApi from "../../../services/auth/useApi";
import { useJuneTrackCall } from "../../../utils/june/analytics";
import TaskListEmptyDropArea from "../TaskListEmptyDropArea";
import TaskRow from "./InboxBlock/TaskRow";
import InboxInput from "./InboxInput";
import { accountState } from "../../../recoil/account/accountStateV2";
import useFetchInProgressBlockQuery from "../../../react-query/inbox/core/useFetchInProgressBlockQuery";
import { useDeleteRecurrenceBlock } from "../../../queries/RecurrenceBlock";
import { useDeleteInboxBlockMutation } from "../../../react-query/inbox/deletion/useDeleteInboxBlockMutation";
import { useUpdateInboxBlockMutation } from "../../../react-query/inbox/core/useUpdateInboxBlockMutation";
import { selectedSpaceIdListState } from "../../../recoil/spaces/selectedSpaceIdListState";
import { filterTasks } from "../../../services/task/task.service";
import {
  CalendarBlockType,
  DraggedEventType,
  InboxBlockType,
} from "../../../types/block/BlockType";
import { InboxHeaderTab, ToggleVisibilityByTab } from "../../../types/inbox/inbox-headers";
import { customScrollbar, useScrollBarStyle } from "../../../hooks/useScrollBarStyle";
import InboxGeneralList from "./InboxList/InboxGeneralList";
import InboxToggleList from "./InboxList/InboxToggleList";
import { pxToRem } from "../../../utils/styles/size";
import { Flex } from "@chakra-ui/react";
import { useInbox3MonthsTaskList } from "../../../hooks/inbox/useInbox3MonthsTaskList";
import { useCreateInboxBlockMutation } from "../../../react-query/inbox/core/useCreateInboxBlockMutation";
import { mobaCalendarListState } from "../../../recoil/calendar/mobaCalendarListState";
import { useDeleteCalendarBlockMutation } from "../../../react-query/calendar/useDeleteCalendarBlockMutation";
import { convertClientBlockTypeToServerBlockType } from "../../../utils/common/block/convertClientBlockTypeToServerBlockType";
import { useHandleBlockDelete } from "../../../hooks/block/useHandleBlockDelete";
import { useOpenRecurringPopup } from "../../../hooks/useOpenRecurringPopup";
import {
  createTaskDividerPayload,
  TaskDividerType,
} from "../../../services/divider/divider.service";
import TaskDividerBlock from "./Divider/TaskDividerBlock";
import { useReorderInboxBlockMutation } from "../../../react-query/inbox/core/useReorderInboxBlockMutation";
import { useDuplicateTaskDividerMutation } from "../../../react-query/inbox/divider/useDuplicateTaskDividerMutation";
import { getPrimaryAccount } from "../../../services/auth/account.service";
import { useDeleteTaskDividerMutation } from "../../../react-query/inbox/divider/useDeleteTaskDividerMutation";
import { inboxTaskListState } from "../../../recoil/taskList/inboxTaskListState";
import { useCreateCalendarBlockMutation } from "../../../react-query/calendar/useCreateCalendarBlockMutation";

import { isInboxBlock } from "../../../services/inbox/inboxType";
import { TaskListWrapper } from "./TaskListWrapper";
import {
  taskPopupState,
  taskPopupVisibleSelector,
} from "../../../recoil/taskDetail/taskPopupState";
import InboxToCalendarDragLayer from "./InboxToCalendarDragLayer";
import { useHandleBlockChange } from "../../../hooks/block/useHandleBlockChange";
import useHandleJuneBlockData from "../../../hooks/june/useHandleJuneBlockdata";
import { deleteNullValueInObjectInInbox } from "../../../utils/taskDetail/formatServerSendData";

interface TaskListProps {
  activeTab: InboxHeaderTab;
  toggleVisibility: ToggleVisibilityByTab;
}

export default function TaskList({ activeTab, toggleVisibility }: TaskListProps) {
  const api = useApi();
  const trackCall = useJuneTrackCall();
  const queryClient = useQueryClient();
  const accountData = useRecoilValue(accountState);
  const draggedEvent = useRecoilValue(draggedEventState);
  const account = useRecoilValue(accountState);

  const selectedSpaceIdList = useRecoilValue(selectedSpaceIdListState);

  const inboxTaskList = useRecoilValue(inboxTaskListState);
  const { mutate: deleteInboxBlockMutate } = useDeleteInboxBlockMutation();
  const { mutate: createCalendarBlock } = useCreateCalendarBlockMutation();
  const { mutate: deleteRecurrenceMutate } = useDeleteRecurrenceBlock();
  const { mutate: updateInboxBlock } = useUpdateInboxBlockMutation();
  const { mutate: createInboxBlockMutate } = useCreateInboxBlockMutation();
  const { mutate: deleteCalendarBlockMutate } = useDeleteCalendarBlockMutation();
  const { mutate: reorderTaskMutate } = useReorderInboxBlockMutation();
  const { mutate: duplicateDivider } = useDuplicateTaskDividerMutation();
  const { mutate: deleteDivider } = useDeleteTaskDividerMutation();
  const { data: inboxTaskListData } = useFetchInProgressBlockQuery();

  const handleJuneBlockData = useHandleJuneBlockData();

  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const resetTaskDetail = useResetRecoilState(taskPopupState);
  const calendarLastMonth = useRecoilValue(calendarLastMonthSelector);
  const { handleEventChange } = useHandleBlockChange({ calendarLastMonth });

  const isTaskDetailVisible = useRecoilValue(taskPopupVisibleSelector);

  const [isTaskListHoverd, setIsTaskListHoverd] = useState(false);
  const [taskSelectedId, setTaskSelectedId] = useState<string | null>(null);
  const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);
  const { handleEventDelete } = useHandleBlockDelete();
  const { openRecurringPopup } = useOpenRecurringPopup();

  const { todayTaskList, inboxPlannedList, overdueList, inboxCompletedList, todayCompletedList } =
    useInbox3MonthsTaskList(selectedSpaceIdList);

  const handleDataDelete = async (data: InboxBlockType | CalendarBlockType) => {
    if ("start" in data) {
      if (!(data.recurrence && data.recurrence.length > 0 && data.recurringEventId)) {
        handleEventDelete(data.id, data.start, data.recurringEventId, "current");

        return api
          .patch(`tasks/${data.id}/mark`, "", {
            headers: { "X-Requester": data.creator },
          })
          .then(() => {
            trackCall("delete_block", {
              location: "calendar",
              type: data.taskType,
            });
          });
      }

      try {
        let selectedRecurringOption;
        if ((data.recurrence && data.recurrence.length > 0) || data.recurringEventId) {
          selectedRecurringOption = await openRecurringPopup(data, "delete");
        }

        deleteRecurrenceMutate({
          eventId: data.id,
          option: selectedRecurringOption,
          notify: false,
          creator: data.creator,
        });

        handleEventDelete(data.id, data.start, data.recurringEventId, selectedRecurringOption);
      } catch (error) {
        console.log(error);
      }
    } else {
      deleteInboxBlockMutate(data);
    }

    handleJuneBlockData({
      action: "delete_block",
      location: activeTab === "Inbox" ? "inbox" : "today_inbox",
      taskType: "Task",
    });
  };

  const handleDataDuplicate = (
    e: React.MouseEvent,
    rowData: InboxBlockType | CalendarBlockType
  ) => {
    const newId = `task-${uuid4()}`;

    let newItem = {
      ...rowData,
      id: newId,
    };

    if ("start" in rowData) {
      const newItemForCalendarBlock: CalendarBlockType = {
        ...rowData,
        id: newId,
        start: rowData.start,
        startTimeZone: rowData.startTimeZone,
        end: rowData.end,
        endTimeZone: rowData.endTimeZone,
        allDay: rowData.allDay || false, // rowData에 allDay가 없을 경우를 대비
        creator: rowData.creator || "",
        title: rowData.title || "",
        visibility: rowData.visibility || "default",
        transparency: rowData.transparency || "opaque",
        recurrence: rowData.recurrence || [],
        taskType: "Task",
      };

      const convertedData = convertClientBlockTypeToServerBlockType(newItemForCalendarBlock);

      handleJuneBlockData({ action: "duplicate_block", location: "today_inbox", taskType: "Task" });
      return createCalendarBlock(convertedData);
    }

    api.get("notes/" + rowData.id + "/" + rowData.creator).then((res) => {
      newItem = {
        ...newItem,
        note: res.data.note || "",
      };

      const tempArr = inboxTaskList
        ? [
            ...inboxTaskList.slice(
              0,
              inboxTaskList.findIndex((item) => item.id === rowData.id) + 1
            ),
            newItem,
            ...inboxTaskList.slice(inboxTaskList.findIndex((item) => item.id === rowData.id) + 1),
          ]
        : [];

      queryClient.setQueryData(["tasks"], tempArr);

      const nullDeletedNewItem = deleteNullValueInObjectInInbox(newItem);
      api
        .post("tasks", nullDeletedNewItem, {
          headers: {
            "X-Requester": accountData?.accountInfo.accounts[0].email,
          },
        })
        .then(() => {
          handleJuneBlockData({ action: "duplicate_block", location: "inbox", taskType: "Task" });

          const taskIdArr = tempArr.map(
            (taskData: InboxBlockType | CalendarBlockType | TaskDividerType) => taskData.id
          );

          const primaryAccountInfo = accountData?.accountInfo.accounts.find(
            (account) => account.type === "primary"
          );

          const newItems = { taskIds: taskIdArr, status: INPROGRESS };

          api
            .post("/tasks/order", newItems, {
              headers: {
                "X-Requester": primaryAccountInfo?.email,
              },
            })
            .then((res) => {})
            .catch((error) => {
              queryClient.invalidateQueries({ queryKey: ["tasks"] });
            });
        });
    });

    return;
  };

  const handleSaveTaskSelectedId = (id: string | null) => {
    setTaskSelectedId(id);
  };

  const [{ isOver }, drop] = useDrop({
    accept: ["integrationDrag"],
    drop(props, monitor) {
      const item: InboxBlockType = monitor.getItem();
      const updatedDragItem: InboxBlockType = {
        ...item,
        itemStatus: INPROGRESS,
        taskType: "Task",
      };

      updateInboxBlock({ creator: updatedDragItem.creator, updates: updatedDragItem });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const handleUpdateEventToTask = async (event: DraggedEventType) => {
    const {
      end,
      start,
      dragType,
      recurrence,
      recurringEventId,
      startTimeZone,
      endTimeZone,
      allDay,
      attendees,
      hangoutLink,
      isCreateSelectEvent,
      recurringNoteId,

      ...rest
    } = event;
    const newTask: InboxBlockType = { ...rest, taskType: "Task", itemStatus: "InProgress" };

    Object.keys(newTask).forEach((key) => {
      if (newTask[key as keyof InboxBlockType] === null) {
        delete newTask[key as keyof InboxBlockType];
      }
    });

    const changedIdNewTask = { ...newTask, id: `task- ${uuid4()}` };
    // NOTE 반복 있는 이벤트일 경우
    if (event.recurrence && event.recurrence.length > 0) {
      // NOTE 클라이언트 상태 업데이트
      setMobaCalendarList((current: CalendarBlockType[] | null) => {
        if (!current) return [];
        const updatedEvents = current?.filter((current) => {
          return !(
            event.id === current.id ||
            event.recurringEventId === current.id ||
            event.recurringEventId === current.recurringEventId
          );
        });
        return updatedEvents;
      });

      // NOTE 서버 상태 업데이트
      deleteRecurrenceMutate({
        eventId: event.id, // 클릭한 요소의 id
        option: "all",
        creator: event.creator,
      });
      createInboxBlockMutate(changedIdNewTask);
      return;
    }

    try {
      deleteCalendarBlockMutate({ creator: newTask.creator, dataId: newTask.id });
      createInboxBlockMutate(changedIdNewTask);
    } catch (error) {
      setMobaCalendarList((prev: CalendarBlockType[] | null) => {
        if (!prev) return [];
        return [...prev, newTask as CalendarBlockType];
      });
      queryClient.setQueryData(["tasks"], inboxTaskList);
    } finally {
      trackCall("move_task");
    }
  };

  const handleDuplicateDivider = (targetId: string) => {
    const email = getPrimaryAccount(account)?.email ?? "";
    const newDivider = createTaskDividerPayload(email, "task");
    duplicateDivider(
      { targetId, newDivider },
      {
        onSuccess: () => {
          trackCall("duplicate_divider", { location: "inbox" });
        },
        onError: (err) => {
          console.error("Error duplicating task divider:", err);
        },
      }
    );
  };

  const handleDeleteDivider = (selectedDividerId: string) => {
    deleteDivider(selectedDividerId, {
      onSuccess: () => {
        trackCall("delete_divider", { location: "inbox" });
      },
    });
  };

  const handleReorder = (taskIdList: string[]) => {
    reorderTaskMutate({
      taskIds: taskIdList,
      status: INPROGRESS,
    });
  };

  const handleClickInboxBlock = useCallback(
    (
      e: React.MouseEvent,
      data: InboxBlockType | CalendarBlockType,
      ref: React.RefObject<HTMLDivElement>
    ) => {
      if (taskDetail.data?.id === data.id) {
        handleSaveTaskSelectedId(null);
        return resetTaskDetail();
      }

      const { clientX, clientY } = e;
      const rect = ref?.current?.getBoundingClientRect();
      const x = (rect?.right ?? 0) + 8;
      const y = rect?.top ?? 0;

      if (clientX !== 0 || clientY !== 0) {
        setTaskDetail({
          isVisible: true,
          data: data,
          modalPosition: { x: x, y: y },
          targetRect: null,
          onClose: () => {
            handleSaveTaskSelectedId(null);
          },
          loadData: null,
          handleDataDuplicate: handleDataDuplicate,
          type: "inbox",
          handleEventDelete: handleEventDelete,
          handleEventChange: handleEventChange,
        });
      }
      handleSaveTaskSelectedId(data.id);

      handleJuneBlockData({
        action: "view_block_detail",
        location: activeTab === "Inbox" ? "inbox" : "today_inbox",
        taskType: "Task",
      });
    },
    [
      setTaskDetail,
      handleSaveTaskSelectedId,
      handleDataDuplicate,
      handleEventDelete,
      handleEventChange,
    ]
  );

  const memoizedTasksList = useMemo(() => {
    if (!inboxTaskList || inboxTaskList.length === 0) {
      return (
        <TaskListEmptyDropArea
          onTasksReorderChange={handleReorder}
          handleUpdateTaskList={updateInboxBlock}
          listType={"inboxTask"}
        />
      );
    }

    return inboxTaskList
      .filter((task: InboxBlockType | TaskDividerType) => {
        // Divider는 항상 표시되도록 true 반환
        if (!isInboxBlock(task)) {
          return true;
        }

        // Task인 경우 space 필터링 적용
        return filterTasks(selectedSpaceIdList, task.projectId);
      })
      .map((taskDataRow: InboxBlockType | TaskDividerType, index: number) => {
        if (!isInboxBlock(taskDataRow)) {
          return (
            <TaskDividerBlock
              key={taskDataRow.id}
              index={index}
              data={taskDataRow}
              isSelected={taskSelectedId === taskDataRow.id}
              onDelete={() => handleDeleteDivider(taskDataRow.id)}
              onDuplicate={() => handleDuplicateDivider(taskDataRow.id)}
              onReorder={handleReorder}
              onSelect={handleSaveTaskSelectedId}
            />
          );
        }

        return (
          // TODO -> InboxBlock으로 통일 필요
          <TaskRow
            key={taskDataRow.id}
            index={index}
            data={taskDataRow}
            isSelected={taskSelectedId === taskDataRow.id}
            onDataDuplicate={handleDataDuplicate}
            onSelect={handleSaveTaskSelectedId}
            listType={"inboxTask"}
            onDataDelete={handleDataDelete}
            isOverdue={false}
            onReorder={handleReorder}
            onClickBlock={handleClickInboxBlock}
          />
        );
      });
  }, [
    selectedSpaceIdList,
    taskSelectedId,
    handleSaveTaskSelectedId,
    isTaskDetailVisible,
    handleClickInboxBlock,
    inboxTaskList,
  ]);

  const flexGrow =
    activeTab === "Inbox"
      ? toggleVisibility.Inbox.overdue ||
        toggleVisibility.Inbox.completed ||
        toggleVisibility.Inbox.planned
        ? 0
        : 1
      : toggleVisibility.Today.overdue || toggleVisibility.Today.completed
        ? 0
        : 1;

  useEffect(
    function resetTaskSelectedId() {
      const isCurrentInboxBlockOpen = taskDetail.data?.id === taskSelectedId;
      isCurrentInboxBlockOpen && handleSaveTaskSelectedId(null);
    },
    [taskDetail.data]
  );

  return (
    <TaskListWrapper>
      <InboxInput activeTab={activeTab} handleSaveTaskSelectedId={handleSaveTaskSelectedId} />
      {activeTab === "Inbox" && (
        <>
          {/* Inbox General 영역 -> 캘린더 요소 여기로 드롭되는 것 설정*/}
          <Flex
            flexGrow={flexGrow}
            gap={pxToRem(6)}
            id="memoizedTasklist"
            borderRadius={"6px"}
            border={isTaskListHoverd ? "2px solid" : "2px solid transparent"}
            borderColor={isTaskListHoverd ? "whiteAlpha.400" : "transparent"}
            flexDirection={"column"}
            height={"fit-content"}
            ref={drop}
            // calendar -> inbox dnd
            onMouseEnter={() => {
              if (draggedEvent && draggedEvent?.dragType === "calendarTask") {
                setIsTaskListHoverd(true);
              }
            }}
            onMouseLeave={() => {
              setIsTaskListHoverd(false);
            }}
            onMouseUp={() => {
              if (draggedEvent && draggedEvent.dragType === "calendarTask") {
                handleUpdateEventToTask(draggedEvent);
                setIsTaskListHoverd(false);
              }
            }}
          >
            {memoizedTasksList}
          </Flex>

          {toggleVisibility?.Inbox.planned && (
            <InboxToggleList
              title={"Planned"}
              list={inboxPlannedList}
              onDataDuplicate={handleDataDuplicate}
              onDataDelete={handleDataDelete}
              taskSelectedId={taskSelectedId}
              onSelect={handleSaveTaskSelectedId}
              onClickInboxBlock={handleClickInboxBlock}
            />
          )}
          {toggleVisibility.Inbox.overdue && (
            <InboxToggleList
              title={"Overdue"}
              list={overdueList}
              onDataDuplicate={handleDataDuplicate}
              onDataDelete={handleDataDelete}
              taskSelectedId={taskSelectedId}
              onSelect={handleSaveTaskSelectedId}
              onClickInboxBlock={handleClickInboxBlock}
            />
          )}
          {toggleVisibility.Inbox.completed && (
            <InboxToggleList
              title={"Completed"}
              list={inboxCompletedList}
              onDataDuplicate={handleDataDuplicate}
              onDataDelete={handleDataDelete}
              taskSelectedId={taskSelectedId}
              onSelect={handleSaveTaskSelectedId}
              onClickInboxBlock={handleClickInboxBlock}
            />
          )}
        </>
      )}

      {activeTab === "Today" && (
        <>
          <InboxGeneralList
            flexGrow={flexGrow}
            activeTab={activeTab}
            taskList={todayTaskList}
            onDataDelete={handleDataDelete}
            onDataDuplicate={handleDataDuplicate}
            taskSelectedId={taskSelectedId}
            onSelect={handleSaveTaskSelectedId}
            onClickInboxBlock={handleClickInboxBlock}
            draggedEvent={draggedEvent}
          />
          {toggleVisibility.Today.overdue && (
            <InboxToggleList
              title={"Overdue"}
              list={overdueList}
              onDataDuplicate={handleDataDuplicate}
              onDataDelete={handleDataDelete}
              taskSelectedId={taskSelectedId}
              onSelect={handleSaveTaskSelectedId}
              onClickInboxBlock={handleClickInboxBlock}
            />
          )}
          {toggleVisibility.Today.completed && (
            <InboxToggleList
              title={"Completed"}
              list={todayCompletedList}
              onDataDuplicate={handleDataDuplicate}
              onDataDelete={handleDataDelete}
              taskSelectedId={taskSelectedId}
              onSelect={handleSaveTaskSelectedId}
              onClickInboxBlock={handleClickInboxBlock}
            />
          )}
        </>
      )}
      <InboxToCalendarDragLayer draggedItemId={draggedEvent?.id} />
    </TaskListWrapper>
  );
}

import { useRef, useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import clsx from "clsx";
import { createPortal } from "react-dom";

import styles from "./DateModal.module.css";

import { taskPopupState } from "../../../recoil/taskDetail/taskPopupState";
import { defaultDurationState, timeFormatState } from "../../../recoil/calendar/settingCalendar";
import formatDate from "../../../utils/common/dateTime/formatDate";
import formatTime from "../../../utils/common/dateTime/formatTime";
import { rRuleToText } from "../../../utils/taskDetail/recurrenceConverter";

import RepeatModal from "./RepeatModal";
import PropertyTitle from "../Common/PropertyTitle";
import RepeatAddModal from "./RepeatAddModal";

import { ReactComponent as DateArrow } from "../../../assets/TaskDetail/date-arrow.svg";
import { ReactComponent as DateIcon } from "../../../assets/TaskDetail/date.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/TaskDetail/delete-icon.svg";
import { ReactComponent as RepeatIcon } from "../../../assets/TaskDetail/repeat-icon.svg";
import moment from "moment";

function checkIfAllDay(eventData) {
  const start = eventData;
  const isAllday = typeof start === "string" && /^\d{4}-\d{2}-\d{2}$/.test(start);
  return isAllday;
}

export default function DateModal({
  handleDateStart,
  handleDateEnd,
  dateInterval,
  isSameDay,
  dateStart,
  setDateStart,
  dateEnd,
  setDateEnd,
  setTimeStart,
  timeStart,
  setTimeEnd,
  timeEnd,
  handleTimeStart,
  handleTimeEnd,
  isAllDay,
  setIsAllDay,
  isDatePropertyModal,
  setIsDatePropertyModal,
  guestError,
  setGuestError,
  handleDateClear,
  setIsDateStart,
  setIsDateEnd,
  setIsTimeStart,
  setIsTimeEnd,
  onCloseRepeat,
  expand,
  refreshDate,
  handleSetModalPosition,
  repeatModalRef,
  repeatAddModalRef,
  isTask,
}) {
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const defaultDuration = useRecoilValue(defaultDurationState);
  const timeFormat = useRecoilValue(timeFormatState);
  const baseDuration = Number(defaultDuration) ?? 15;

  const [isRepeatModalOn, setIsRepeatModalOn] = useState(false);
  const [isClearButton, setIsClearButton] = useState(false);
  const [isRepeatAddModal, setIsRepeatAddModal] = useState(false);
  // custom repeat을 제외한 기본 repeat 설정여부
  const [isRepeatSetted, setIsRepeatSetted] = useState(
    taskDetail.data && taskDetail.data.recurrence && taskDetail.data.recurrence.length > 0
  );
  // custom repeat 설정여부
  const [isCustomRepeatSetted, setIsCustomRepeatSetted] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });

  const nowDate = new Date();
  const minutes = nowDate.getMinutes();
  const roundedMinutes = Math.ceil(minutes / baseDuration) * baseDuration;
  nowDate.setMinutes(roundedMinutes);
  const { data } = taskDetail;

  const wrapRef = useRef(null);
  const triggerRef = useRef(null);
  const parentDivRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (
        wrapRef.current &&
        !wrapRef.current.contains(event.target) &&
        !repeatModalRef.current.contains(event.target)
      ) {
        setIsDatePropertyModal(false);
        setIsDateStart(false);
        setIsDateEnd(false);
        setIsTimeStart(false);
        setIsTimeEnd(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapRef]);

  useEffect(() => {
    if (isRepeatModalOn) {
      const button = document.getElementById("repeat_btn");
      const rect = button.getBoundingClientRect();
      setPosition({ top: rect.top + window.scrollY + 15, left: rect.left + window.scrollX });
    }
  }, [isRepeatModalOn]);

  useEffect(() => {}, [isCustomRepeatSetted]);

  const handleOnMouseEnter = (e) => {
    e.stopPropagation();
    setIsClearButton(true);
  };

  const handleOnMouseLeave = () => {
    setIsClearButton(false);
  };

  const handleOnDelete = (event) => {
    event.stopPropagation();
    handleDateClear();
  };

  const handleClickRepeatModal = () => {
    setIsRepeatModalOn((prev) => !prev);
  };

  const handleIsPropertyModal = () => {
    const start = dateStart ? dateStart : nowDate;
    const end = dateEnd ? dateEnd : new Date(nowDate.getTime() + baseDuration * 60000);

    setDateStart(start);
    setDateEnd(end);
    setTimeStart(start);
    setTimeEnd(end);
    setGuestError(false);
  };

  const handleIsAllDay = (isAllDay) => {
    // 시간 설정용
    const baseStart = new Date(nowDate.getTime());
    const baseEnd = new Date(nowDate.getTime() + baseDuration * 60000);

    // 날짜 설정용
    const start = dateStart ? dateStart : nowDate;
    const end = dateEnd ? dateEnd : nowDate;

    if (!isAllDay) {
      start.setHours(baseStart.getHours());
      start.setMinutes(baseStart.getMinutes());

      end.setHours(baseEnd.getHours());
      end.setMinutes(baseEnd.getMinutes());
    }

    setDateStart(start);
    setDateEnd(end);
    setTimeStart(baseStart);
    setTimeEnd(baseEnd);

    // taskDetail 업데이트
    refreshDate(start, end, baseStart, baseEnd);
  };

  return (
    <div className={styles.dateInputWrapper} ref={parentDivRef}>
      <div
        ref={triggerRef}
        className={styles["setting-property-btn"]}
        onClick={handleIsPropertyModal}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <PropertyTitle Icon={DateIcon} label="Date/Time" expand={expand} />
        <div
          className={clsx({
            [styles["date_property--expand"]]: expand,
            [styles["date_property"]]: !expand,
          })}
        >
          {dateStart === null ? (
            <button className={styles.property__empty_title}>
              <span>Add Date/Time</span>
            </button>
          ) : (
            <div className={styles["date__total-datetime"]}>
              <div
                className={clsx(styles["date__start-datetime"], {
                  [styles["date__datetime--expand"]]: expand,
                })}
              >
                {!isAllDay && (
                  <div
                    onClick={(event) => {
                      event.preventDefault();
                      setIsTimeStart((prev) => !prev);
                      handleSetModalPosition(event);
                    }}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                    className={styles["date__start-time"]}
                  >
                    <span
                      className={`${styles["setting-property-label"]}
                ${timeStart != null && styles["label-on"]}`}
                    >
                      {timeStart === null ? "Date/Time" : formatTime(timeStart, timeFormat)}
                    </span>
                  </div>
                )}
                <div
                  onClick={(event) => {
                    setIsDateStart((prev) => !prev);
                    handleSetModalPosition(event);
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  className={styles["date__start-date"]}
                >
                  <span
                    className={`
                ${styles["setting-property-label"]}
                ${dateStart != null && styles["label-on"]}`}
                  >
                    {dateStart === null ? "Date/Time" : formatDate(dateStart, timeFormat)}
                  </span>
                </div>
              </div>
              <div className={styles.date_partition}>
                <DateArrow />
              </div>
              <div
                className={clsx(styles["date__end-datetime"], {
                  [styles["date__datetime--expand"]]: expand,
                })}
              >
                {!isAllDay && (
                  <div
                    onClick={(event) => {
                      setIsTimeEnd((prev) => !prev);
                      handleSetModalPosition(event);
                    }}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                    className={styles["date__end-time"]}
                  >
                    <span
                      className={`
                ${styles["setting-property-label"]}
                ${timeEnd != null && styles["label-on"]}`}
                    >
                      {timeEnd === null ? "Date/Time" : formatTime(timeEnd, timeFormat)}
                    </span>
                  </div>
                )}
                <div
                  onClick={(event) => {
                    setIsDateEnd((prev) => !prev);
                    handleSetModalPosition(event);
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  className={styles["date__end-date"]}
                >
                  <span
                    className={`
                ${styles["setting-property-label"]}
                ${dateEnd != null && styles["label-on"]}`}
                  >
                    {dateEnd != null && formatDate(dateEnd)}
                  </span>
                </div>
              </div>
            </div>
          )}
          {dateStart !== null && (
            <div className={styles.time_sub_btns}>
              {dateStart !== null && dateInterval && (
                <span className={styles["date-interval"]}>{dateInterval}</span>
              )}
              {dateStart !== null && (
                <>
                  <div
                    className={clsx({
                      [styles["toggle_container"]]: !isAllDay,
                      [styles["toggle_container--selected"]]: isAllDay,
                    })}
                    onClick={() => {
                      setIsAllDay((prevIsAllDay) => {
                        const newIsAllDay = !prevIsAllDay;
                        handleIsAllDay(newIsAllDay);
                        return newIsAllDay;
                      });
                    }}
                  >
                    <label className={styles.toggle}>
                      <input
                        type="checkbox"
                        checked={isAllDay}
                        onChange={() => {
                          setIsAllDay((prev) => !prev);
                          handleIsAllDay(isAllDay);
                        }}
                      />
                      <span className={styles.slider}></span>
                    </label>
                    <span className={styles.toggle_label}>All day</span>
                  </div>

                  <button
                    id="repeat_btn"
                    className={clsx({
                      [styles.repeat_btn]: !isRepeatSetted,
                      [styles["repeat_btn--setted"]]: isRepeatSetted || isCustomRepeatSetted,
                      [styles["repeat_btn--active"]]: isRepeatModalOn,
                    })}
                    onClick={handleClickRepeatModal}
                  >
                    <div className={styles.iconDescription}>
                      {isRepeatSetted || isCustomRepeatSetted
                        ? rRuleToText(data.recurrence)
                        : "Repeat"}
                    </div>
                    <RepeatIcon className={styles.repeatIcon} />
                    {isRepeatModalOn &&
                      createPortal(
                        <RepeatModal
                          onClose={onCloseRepeat}
                          position={position}
                          isRepeatSetted={isRepeatSetted}
                          setIsRepeatSetted={setIsRepeatSetted}
                          setIsRepeatModalOn={setIsRepeatModalOn}
                          setIsRepeatAddModal={setIsRepeatAddModal}
                          isCustomRepeatSetted={isCustomRepeatSetted}
                          setIsCustomRepeatSetted={setIsCustomRepeatSetted}
                          repeatModalRef={repeatModalRef}
                        />,
                        document.body
                      )}
                  </button>
                  {!data.isCreateSelectEvent && isTask && (
                    <div className={styles.selectedGuestRowDelete} onClick={handleOnDelete}>
                      <div className={styles.iconDescription}>Delete</div>
                      <DeleteIcon className={styles.selectedGuestRowDeleteIcon} />
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        {guestError ? <div className={styles.validationErrorIcon}></div> : null}
      </div>
      {isDatePropertyModal && (
        <div ref={wrapRef} className={styles["wrap"]}>
          <div className={styles["date-toggle"]}>
            <span className={styles["date-part-title"]}>all day</span>
            <label className={styles["date-switch"]}>
              <input
                className={styles["date-input"]}
                type="checkbox"
                checked={isAllDay ? true : false}
                // onChange={(e) => {
                //   e.stopPropagation();
                //   handleIsAllDay(isAllDay);
                // }}
              ></input>
              <span className={styles["date-slider"]}></span>
            </label>
          </div>
          <div className={styles["date-part"]}>
            <span className={styles["date-part-title"]}>Start</span>
            <div className={styles["date-row"]}>
              <div
                className={styles["date-dropdown-date"]}
                onClick={(event) => {
                  event.stopPropagation();
                  handleDateStart(event);
                }}
              >
                <span
                  className={`${styles["dropdown-date-title"]} ${isSameDay && styles["disable"]}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDateStart(event);
                  }}
                >
                  {dateStart != null && formatDate(dateStart)}
                </span>
              </div>
              {!isAllDay && (
                <div
                  className={`${styles["date-dropdown-time"]} `}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleTimeStart(event);
                  }}
                >
                  {formatTime(timeStart, timeFormat)}
                </div>
              )}
            </div>
          </div>
          <div className={styles["date-part"]}>
            <span className={styles["date-part-title"]}>End</span>
            <div className={styles["date-row"]}>
              <div
                className={styles["date-dropdown-date"]}
                onClick={(event) => {
                  event.stopPropagation();
                  handleDateEnd(event);
                }}
              >
                <span
                  className={`${styles["dropdown-date-title"]} ${isSameDay && styles["disable"]}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDateEnd(event);
                  }}
                >
                  {dateEnd != null && formatDate(dateEnd)}
                </span>
              </div>
              {!isAllDay && (
                <div
                  className={`${styles["date-dropdown-time"]} ${"date-dropdown-time"}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleTimeEnd(event);
                  }}
                >
                  {formatTime(timeEnd, timeFormat)}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isRepeatAddModal && (
        <RepeatAddModal
          isCustomRepeatSetted={isCustomRepeatSetted}
          setIsRepeatAddModal={setIsRepeatAddModal}
          setIsCustomRepeatSetted={setIsCustomRepeatSetted}
          repeatAddModalRef={repeatAddModalRef}
          dateStart={dateStart}
        />
      )}
    </div>
  );
}

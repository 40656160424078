import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { meetWithAccountsState } from "../../recoil/account/accountState";
import useApi from "../../services/auth/useApi";
import { useEffect, useRef, useState } from "react";
import { toastState } from "../../recoil/toast/toastState";
import { calendarEventDuplicateItemState } from "../../recoil/calendar/calendarState";
import { mobaCalendarListState } from "../../recoil/calendar/mobaCalendarListState";
import { useOpenRecurringPopup } from "../../hooks/useOpenRecurringPopup";
import { useOpenGuestPopup } from "../../hooks/useOpenGuestPopup";
import { useDeleteRecurrenceBlock } from "../../queries/RecurrenceBlock";
import { useDrop } from "react-dnd";
import { useJuneTrackCall } from "../../utils/june/analytics";
import { createPortal } from "react-dom";
import SidebarModal from "../TaskInbox/SidebarModal";
import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";

export function CustomEventWrapper({
  event,
  children,
  onClick,
  loadData,
  onEventDelete,
  onDataDuplicate,
  onEventChange,
}) {
  const meetWithAccounts = useRecoilValue(meetWithAccountsState);
  const taskDetail = useRecoilValue(taskPopupState);
  const resetTaskDetail = useResetRecoilState(taskPopupState);

  const api = useApi();
  const [isExpand, setIsExpand] = useState(null);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [isSidebarModalOn, setIsSidebarModalOn] = useState(false);
  const setToast = useSetRecoilState(toastState);
  const setDuplicateItem = useSetRecoilState(calendarEventDuplicateItemState);
  const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);

  const { openRecurringPopup } = useOpenRecurringPopup();
  const { openGuestPopup } = useOpenGuestPopup();

  const { mutate: deleteRecurrenceMutate } = useDeleteRecurrenceBlock();

  const wrapRef = useRef(null);
  const [, drop] = useDrop({
    accept: ["inbox"],
    drop: (event) => {},
  });
  const trackCall = useJuneTrackCall();
  useEffect(() => {
    if (event.isDataDuplicateEvent) {
      if (event.attendees && event.attendees.length > 0) {
        let eventsContainer = wrapRef.current.closest(".rbc-event");

        if (!eventsContainer) {
          eventsContainer = wrapRef.current.querySelector(".rbc-event");
        }
        setDuplicateItem(eventsContainer);
        const rect = eventsContainer ? eventsContainer.getBoundingClientRect() : null;

        if (rect) {
          const newEvent = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
            clientX: rect.left + rect.width,
            clientY: rect.top + rect.height,
          });

          wrapRef.current.dispatchEvent(newEvent);
        }
      } else {
        onEventChange({ ...event, isDataDuplicateEvent: false });
      }
    }
  }, [event]);

  const handleEvent = (e) => {
    if (!event.data) return;
    const { clientX, clientY } = e;
    setModalPosition({ x: clientX, y: clientY });
    setIsExpand(false);
  };

  const handleItemContextMenu = (e) => {
    e.preventDefault();
    //inputRef.current.blur();
    const { clientX, clientY } = e;

    // allDay영역은 Fixed position이 위의 부모 Div에서 Translate옵션이 있어서, 웹페이지 전체가 아닌 부모에 묶여버린다.

    setModalPosition({ x: clientX, y: clientY });

    //getBoundingClientRect().left + window.scrollX
    setIsSidebarModalOn(true);
  };

  const updateMobaEventListForDeletion = (event, selectedOption) => {
    setMobaCalendarList((currentList) => {
      if (selectedOption === "current") {
        // 해당 이벤트만 삭제
        return currentList.filter((e) => e.id !== event.id);
      }

      if (selectedOption === "all") {
        return currentList.filter((e) => {
          // 조건: recurringEventId가 같거나, id가 recurringEventId와 같은 경우 삭제
          return !(
            event.recurringEventId === e.recurringEventId || event.recurringEventId === e.id
          );
        });
      }
      if (selectedOption === "from") {
        // 선택된 이벤트 이후의 모든 이벤트 삭제
        const selectedEventStart = new Date(event.start); // event.start는 ISO 형식 문자열임

        return currentList.filter((e) => {
          // 각 이벤트의 시작 시간을 Date 객체로 변환
          const eventStart = new Date(e.start);

          // 조건: recurringEventId가 같고 선택한 이벤트 이후의 이벤트는 삭제
          return !(
            e.recurringEventId === event.recurringEventId && eventStart >= selectedEventStart
          );
        });
      }

      // 기본적으로 아무 변화 없음
      return currentList;
    });
  };

  // 반복 이벤트 삭제를 위한 API 호출 함수
  const deleteRecurringEvent = async (event, selectedOption, notifyGuests) => {
    // 서버에 삭제 요청 전 미리 삭제 처리
    updateMobaEventListForDeletion(event, selectedOption);

    // 서버에 실제 삭제 요청
    deleteRecurrenceMutate({
      eventId: event.id, // 클릭한 요소의 id
      option: selectedOption,
      notify: notifyGuests,
      creator: event.creator,
    });
  };

  const handleDelete = async () => {
    // 캘린더에서 우클릭 contextMenu로 삭제 시
    setIsSidebarModalOn(false);

    // 이벤트가 반복 이벤트인지 확인 만약 해당 이벤트가 반복 이벤트라면 삭제 옵션 창 띄우기
    if ((event.recurrence && event.recurrence.length > 0) || event.recurringEventId) {
      // RecurringPopup을 Promise로 처리

      try {
        // 1. RecurringPopup에서 반복 이벤트 옵션 선택
        let selectedRecurringOption;
        if ((event.recurrence && event.recurrence.length > 0) || event.recurringEventId) {
          selectedRecurringOption = await openRecurringPopup(event, "delete");
        }

        // 2. 게스트가 있을 경우 GuestPopup에서 알림 여부 선택
        let notifyGuests = false;

        if (event.attendees && event.attendees.length > 0) {
          notifyGuests = await openGuestPopup(event, "delete");
        }

        // 3. 선택된 옵션과 게스트 알림 여부를 기반으로 API 호출
        await deleteRecurringEvent(event, selectedRecurringOption, notifyGuests);

        // 성공적으로 이벤트가 삭제되었음을 사용자에게 알림
        setToast({
          type: "Delete",
          isVisible: true,
          message: "Event has been deleted",
        });
      } catch (error) {
        console.error("Event deletion cancelled or failed", error);
      }
    } else {
      // 클라이언트 상태 배열에서 삭제하는 로직

      let notifyGuests = false;

      if (event.attendees && event.attendees.length > 0) {
        notifyGuests = await openGuestPopup(event, "delete");
      }

      setToast({
        type: "Delete",
        isVisible: true,
        message: "Event has been deleted",
      });

      onEventDelete(event.id);
      api
        .patch(`tasks/${event.id}/mark`, "", {
          params: {
            notification: notifyGuests,
          },
          headers: { "X-Requester": event.creator },
        })
        .then(() => {
          trackCall("delete_block", {
            location: "calendar",
            type: event.taskType,
          });
        });
    }
  };

  const handleDataDuplicate = (e) => {
    onDataDuplicate(e, event);
    setIsSidebarModalOn(false);
  };

  const handleClickEvent = (e, event, refBound) => {
    if (taskDetail.data?.id === event.id) {
      return resetTaskDetail();
    }
    onClick(e, event, refBound);
  };

  return (
    <>
      <div ref={drop} key={event.id}>
        <div
          ref={wrapRef}
          onContextMenu={handleItemContextMenu}
          onClick={(e) => {
            handleClickEvent(e, event, wrapRef.current.getBoundingClientRect());
          }}
          onMouseDown={(e) => {
            const isBlockClickOutSide = !event.isCreateSelectEvent && taskDetail.isVisible;
            isBlockClickOutSide && e.stopPropagation();
          }}
        >
          {children}
        </div>
      </div>
      {isSidebarModalOn &&
        createPortal(
          <SidebarModal
            x={modalPosition.x}
            y={modalPosition.y}
            onDelete={handleDelete}
            onDuplicate={handleDataDuplicate}
            onClose={() => setIsSidebarModalOn(false)}
          />,
          document.body
        )}
    </>
  );
}

import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { calendarViewState, showMorePopupState } from "../../recoil/calendar/calendarState";
import {
  inProgressSelectedSpaceIdListSelector,
  selectedSpaceIdListStateSelector,
} from "../../recoil/spaces/selectedSpaceIdListState";
import {
  defaultDurationState,
  startOfWeekState,
  timeFormatState,
  visibilityState,
} from "../../recoil/calendar/settingCalendar";
import { toastState } from "../../recoil/toast/toastState";
import useFetchCalendarEvents from "../../hooks/useFetchCalendarEvents";
import { useUpdateRecurrenceBlock } from "../../queries/useUpdateRecurrenceBlock";
import {
  calendarLastMonthSelector,
  draggedEventState,
} from "../../recoil/calendar/calendarStateV2";
import moment from "moment";
import { mobaCalendarListState } from "../../recoil/calendar/mobaCalendarListState";
import { useOpenRecurringPopup } from "../../hooks/useOpenRecurringPopup";
import { useOpenGuestPopup } from "../../hooks/useOpenGuestPopup";
import useApi from "../../services/auth/useApi";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import CustomDay from "./CustomDay";
import { loadFromLocalStorage, saveToLocalStorage } from "../../utils/localStorage/localStorage";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { CalendarViewType, MeetWithColors } from "../../constants";
import { meetWithAccountsState } from "../../recoil/account/accountState";
import { accountState } from "../../recoil/account/accountStateV2";
import { inboxTaskListState } from "../../recoil/taskList/inboxTaskListState";
import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";
import { doneTaskListState } from "../../recoil/taskList/doneTaskListState";
import { trashRestoreCalendarEventState } from "../../recoil/trash/trashRestoreCalendarEventState";
import useClickOutside from "../../hooks/useClickOutside";
import { useJuneTrackCall } from "../../utils/june/analytics";
import useFetchMeetingCode from "../../queries/TaskDetail/useFetchMeetingCode";
import { useCalendarEventQueries } from "../../queries/Calendar";
import { filterTasks } from "../../services/task/task.service";
import { convertGoogleCalendarToMobaCalendar } from "../../utils/calendar/convertGoogleCalendarToMobaCalendar";
import { areDateEqual } from "../../utils/common/dateTime/areDateEqual";

import { v4 as uuid4 } from "uuid";
import formatDateTimeForJune from "../../utils/june/formatDateTimeForJune";
import UserCancelledPopupError from "../../errors/UserCancelledPopupError";
import { convertClientBlockTypeToServerBlockType } from "../../utils/common/block/convertClientBlockTypeToServerBlockType";
import { extractSpaceId } from "../../services/space/space.service";
import CalendarHeader from "./header";
import CustomEvent from "./CustomEvent";
import overlap from "react-big-calendar/lib/utils/layout-algorithms/overlap";
import ShowMoreTrigger from "./ShowMorePopup/ShowMoreTrigger";
import { createPortal } from "react-dom";
import ShowMorePopup from "./ShowMorePopup";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { MyTimeGutterHeader } from "./MyTimeGutterHeader";
import { MyDayHeader } from "./MyDayHeader";
import { MyDayWeekHeader } from "./MyDayWeekHeader";
import { MyTimeGutterWrapper } from "./MyTimeGutterWrapper";
import { CustomEventWrapper } from "./CustomEventWrapper";
import { useUpdateCalendarBlockMutation } from "../../react-query/calendar/useUpdateCalendarBlockMutation";
import { getPrimaryAccount } from "../../services/auth/account.service";
import { useHandleBlockChange } from "../../hooks/block/useHandleBlockChange";
import { useHandleBlockDelete } from "../../hooks/block/useHandleBlockDelete";
import { useInitialCalenadarBlocks } from "../../hooks/calendar/useInitialCalendarBlocks";
import { deleteNullValueInObject } from "../../utils/taskDetail/formatServerSendData";
import { all } from "axios";
import {
  defaultDurationCalculatedEndTime,
  isAllDayEvent,
} from "../../utils/calendar/calculateDropEvent";

moment.updateLocale("en", {
  week: {
    dow: 0,
    doy: 1,
  },
});

export const MyCalendar = ({ loadData }) => {
  // 1. Local States (UI 관련 상태)
  const [localizer, setLocalizer] = useState(momentLocalizer(moment));
  const [showMoreEvents, setShowMoreEvents] = useState(null);
  const [previewSlot, setPreviewSlot] = useState(null);
  const [meetWithEvents, setMeetWithEvents] = useState([]);

  // 2. Refs
  const currentMoreTriggerRef = useRef(null);
  const clickListenerRef = useRef(null);
  const showMorePopupPos = useRef(null);

  // 3. Recoil States - 읽기/쓰기
  const [calendarView, setCalendarView] = useRecoilState(calendarViewState);
  const [mobaCalendarList, setMobaCalendarList] = useRecoilState(mobaCalendarListState);
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const [draggedEvent, setDraggedEvent] = useRecoilState(draggedEventState);
  const [showMorePopup, setShowMorePopup] = useRecoilState(showMorePopupState);
  const [meetWithAccounts, setMeetWithAccounts] = useRecoilState(meetWithAccountsState);
  const [trashRestoreCalendarEvent, setTrashRestoreCalendarEvent] = useRecoilState(
    trashRestoreCalendarEventState
  );

  // 4. Recoil States - 쓰기 전용
  const setToast = useSetRecoilState(toastState);
  const updateInboxTaskList = useSetRecoilState(inboxTaskListState);
  const updateInboxCompletedTaskList = useSetRecoilState(doneTaskListState);
  const resetTaskDetail = useResetRecoilState(taskPopupState);

  // 5. Recoil States - 읽기 전용
  const startOfWeek = useRecoilValue(startOfWeekState);
  const defaultVisibility = useRecoilValue(visibilityState);
  const calendarLastMonth = useRecoilValue(calendarLastMonthSelector);
  const account = useRecoilValue(accountState);
  const inProgressSelectedSpaceIdList = useRecoilValue(inProgressSelectedSpaceIdListSelector);
  const selectedSpaceIdList = useRecoilValue(selectedSpaceIdListStateSelector);
  const defaultDuration = useRecoilValue(defaultDurationState);

  // 6. Custom Hooks
  const { openRecurringPopup } = useOpenRecurringPopup();
  const { openGuestPopup } = useOpenGuestPopup();
  const { processInitialCalendarBlocks } = useInitialCalenadarBlocks();
  const { handleEventChange } = useHandleBlockChange({ calendarLastMonth });
  const { handleEventDelete } = useHandleBlockDelete();

  // 7. Queries
  const { result: fetchCalendarEvents, invalidateCalendarEvents } = useFetchCalendarEvents();
  const { targetRef, triggerRef } = useClickOutside(() => {
    setShowMorePopup(false);
  });

  // 8. Mutations
  const { mutateMeetingCode } = useFetchMeetingCode();
  const { mutate: updateRecurrenceMutate } = useUpdateRecurrenceBlock();
  const { mutate: updateCalendarBlock } = useUpdateCalendarBlockMutation();

  // 9. Utils & Others
  const trackCall = useJuneTrackCall();
  const email = getPrimaryAccount(account)?.email ?? "";
  const api = useApi();

  const { views } = useMemo(
    () => ({
      views: {
        month: true,
        week: true,
        day: true,
        day3: CustomDay,
      },
    }),
    []
  );

  const calendarRef = useRef(null);

  const handleView = useCallback((newView) => {
    const timeViewMap = {
      day: "1day",
      day3: "3days",
      week: "weekly",
      month: "monthly",
    };
    newView !== CalendarViewType.MONTH.type &&
      setTimeout(() => {
        const wrapper = document.querySelector(".rbc-time-content");
        const element = document.querySelector(`.rbc-current-time-indicator`);
        if (!wrapper || !element) {
          return;
        }
        const wrapperBound = wrapper.getBoundingClientRect();
        wrapper.scrollTo({
          top: element.offsetTop - wrapperBound.height / 3,
          behavior: "smooth",
        });
      }, 200);

    setCalendarView(newView);
    saveToLocalStorage("calendarViewType", newView);

    trackCall("change_calendar_view", { type: timeViewMap[newView] });
  }, []);

  const calendarEventQueries = useCalendarEventQueries(
    meetWithAccounts.map((account) => account.email)
  );

  useEffect(() => {
    const targetNode = currentMoreTriggerRef.current;

    if (targetNode) {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList") {
            if (!document.contains(targetNode)) {
              currentMoreTriggerRef.current = null;
              setShowMorePopup(false);
            }
          }
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    }
  }, [showMorePopup]);

  useLayoutEffect(() => {
    const updateLocale = (locale, dow) => {
      moment.updateLocale(locale, {
        week: {
          dow, // week: 0 (일요일부터) or 1 (월요일부터)
          doy: 1,
        },
      });
    };

    if (startOfWeek === "Monday") {
      updateLocale("ko", 1);
    } else {
      updateLocale("en", 0);
    }

    setLocalizer(momentLocalizer(moment));
  }, [startOfWeek]);

  useEffect(() => {
    if (!fetchCalendarEvents.data) return;

    const initialProcessedCalendarBlocks = processInitialCalendarBlocks(
      fetchCalendarEvents.data,
      calendarLastMonth
    );

    setMobaCalendarList(initialProcessedCalendarBlocks);
  }, [fetchCalendarEvents.data, processInitialCalendarBlocks]);

  useEffect(() => {
    if (!calendarEventQueries.data || calendarEventQueries.pending || !account) return;

    const convertMobaCalendar = calendarEventQueries.data.map((accountEvent, idx) => {
      const primaryAccountInfo = account.accountInfo.accounts.find((account) => {
        return account.type === "primary";
      });
      const creator = primaryAccountInfo.email;
      const currentMeetWithAccount = meetWithAccounts[idx].email;
      return (
        (accountEvent &&
          (creator !== currentMeetWithAccount
            ? initialConvertGoogleCalendarToMobaCalendar(accountEvent.items)
            : [])) ??
        []
      );
    });

    const meetWith = convertMobaCalendar.flatMap((accountEvent, idx) =>
      accountEvent.flatMap((items) => ({
        ...items,
        backgroundColor: MeetWithColors[idx % MeetWithColors.length],
        isMeetWith: true,
      }))
    );

    setMeetWithEvents(meetWith);
  }, [calendarEventQueries.data, account]);

  useEffect(() => {
    calendarEventQueries.error.forEach(
      (error, errorIdx) =>
        error &&
        setMeetWithAccounts((account) =>
          account.map((item, idx) => (errorIdx === idx ? { ...item, isAccess: false } : item))
        )
    );
  }, [calendarEventQueries.error]);

  const myEvents = useMemo(() => {
    return mobaCalendarList
      .filter((task) => filterTasks(selectedSpaceIdList, task?.projectId))
      .map((event) => ({ ...event }));
  }, [mobaCalendarList, selectedSpaceIdList]);

  useEffect(() => {
    if (trashRestoreCalendarEvent) {
      const convertEvent = convertGoogleCalendarToMobaCalendar(trashRestoreCalendarEvent);
      setMobaCalendarList((current) => {
        return [...current, convertEvent];
      });
      setTrashRestoreCalendarEvent(null);
    }
  }, [trashRestoreCalendarEvent, setTrashRestoreCalendarEvent]);

  const initialConvertGoogleCalendarToMobaCalendar = (googleCalendarEvent) => {
    return googleCalendarEvent
      .filter(
        (it) =>
          it.start &&
          (it.start || it.start?.date || it.start?.dateTime) &&
          it.end &&
          (it.end || it.end?.date || it.end?.dateTime)
      )
      .map((it) => {
        return convertGoogleCalendarToMobaCalendar(it);
      });
  };

  // NOTE unused Function
  // const handleGoogleMeetUrlInsertChange = (eventData) => {
  //   setMobaCalendarList((current) => {
  //     const updatedEvents = [...current];
  //     const eventIndex = updatedEvents.findIndex((event) => event.id === eventData.id);
  //     if (eventIndex !== -1) {
  //       updatedEvents[eventIndex].attendees = eventData.attendees;
  //       updatedEvents[eventIndex].hangoutLink = eventData.hangoutLink;
  //     }
  //     return updatedEvents;
  //   });
  // };

  const eventPropGetter = useCallback((event, start, end, isSelected) => {
    return {
      ...{
        className: `isDraggable ${event.allDay ? "allDay-event" : ""} ${event.isMeetWith ? "meetWith-event" : ""}`,
      },
    };
  }, []);

  const handleDataDuplicate = (e, rowData) => {
    const newId = `task-${uuid4()}`;

    let newItem = convertGoogleCalendarToMobaCalendar({
      ...rowData,
      id: newId,
      isDataDuplicateEvent: true,
      recurrence: rowData?.recurrence || [],
      recurringEventId: rowData?.recurringEventId,
    });

    let newItemForAPI = { ...newItem };
    for (const key in newItemForAPI) {
      if (newItemForAPI[key] === null) {
        delete newItemForAPI[key];
      }
    }

    if (rowData.hangoutLink) {
      const meetingCode = rowData.hangoutLink.replace("https://meet.google.com/", "");
      newItemForAPI = {
        ...newItemForAPI,
        meetingCode: meetingCode,
      };
    }
    // 1. 게스트가 없는 경우 처리
    if (
      (!newItem.attendees || newItem.attendees.length === 0) &&
      (!newItem?.recurrence || newItem.recurrence.length === 0) &&
      !newItem.recurringEventId
    ) {
      newItemForAPI = {
        ...newItemForAPI,
        projectId: newItem.projectId != null ? newItem.projectId : "",
        start: newItem.startTimeZone
          ? { dateTime: newItem.start, timeZone: newItem.startTimeZone }
          : { date: moment(newItem.start).format("YYYY-MM-DD") },
        end: newItem.endTimeZone
          ? { dateTime: newItem.end, timeZone: newItem.endTimeZone }
          : { date: moment(newItem.end).format("YYYY-MM-DD") },
      };

      // Note 데이터를 가져와서 API 요청을 보냄
      api.get("notes/" + rowData.id + "/" + rowData.creator).then((res) => {
        newItemForAPI = {
          ...newItemForAPI,
          note: res.data.note || "",
        };

        setToast({
          type: "Success",
          isVisible: true,
          message: "Event created successfully",
        });

        // 복제된 이벤트를 서버에 저장
        api
          .post("tasks", newItemForAPI, {
            headers: {
              "X-Requester": newItem.creator,
            },
          })
          .then((res) => {
            let trackObject = { location: "calendar" };
            if (newItem.attendees != null && newItem.attendees.length) {
              trackObject = { ...trackObject, type: "meeting" };
            } else {
              trackObject = { ...trackObject, type: "task" };
            }
            trackCall("duplicate_block", trackObject);

            // MobaEventList에 복제된 이벤트 추가
            setMobaCalendarList((current) => [...current, newItem]);

            // TaskDetail 창 닫기
            setTaskDetail((prevState) => ({ ...prevState, isVisible: false }));

            // 성공 토스트 메시지 표시
          })
          .catch((error) => {
            console.error("Failed to duplicate task: ", error);
          });
      });
    } else {
      // 2. 게스트가 있는 경우 처리
      const { clientX, clientY } = e;

      setTaskDetail((prevState) => ({
        ...prevState,
        isVisible: true,
        // TODO 여기 taskDetail type
        data: newItemForAPI,
        modalPosition: { x: clientX, y: clientY },
        loadData: loadData,
        handleDataDuplicate: handleDataDuplicate,
        type: "calendar",
        handleEventDelete: handleEventDelete,
        handleEventChange: handleEventChange,
      }));
      setMobaCalendarList((current) => [...current, newItemForAPI]);
    }
  };

  const customOnDragOver = useCallback(
    (dragEvent) => {
      if (draggedEvent == null) {
        return;
      }
      if (draggedEvent !== "undroppable") {
        dragEvent.preventDefault();
      }

      // 슬롯 정보 가져오기
      const x = dragEvent.clientX;
      const y = dragEvent.clientY;
      const slot = getSlotAtXAndY(x, y);

      if (slot && (!previewSlot || slot.getTime() !== previewSlot[0].getTime())) {
        const slots = [];
        const slotDuration = 15; // 기본 슬롯 시간 (분)
        const numberOfSlots = defaultDuration / slotDuration;

        for (let i = 0; i < numberOfSlots; i++) {
          const newSlot = new Date(slot.getTime() + i * slotDuration * 60000);
          slots.push(newSlot);
        }

        setPreviewSlot(slots);
      } else if (!slot && previewSlot) {
        setPreviewSlot(null);
      }
    },

    [draggedEvent, previewSlot]
  );

  const getSlotAtXAndY = (x, y) => {
    const timeslotElements = Array.from(document.querySelectorAll(".rbc-time-slot"));
    const foundElement = timeslotElements.find((element) => {
      const rect = element.getBoundingClientRect();
      return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
    });

    if (foundElement) {
      const slotTime = new Date(foundElement.getAttribute("data-datetime"));
      return slotTime;
    }
    return null;
  };

  const slotPropGetter = (date) => {
    const formattedDate = date.toISOString();
    const isInboxEvent = draggedEvent && (!draggedEvent.start || draggedEvent.start.length === 0);
    const slotStyle =
      isInboxEvent && previewSlot && previewSlot.some((slot) => date?.getTime() === slot.getTime())
        ? { backgroundColor: "rgba(255, 255, 255, 0.04)" }
        : {};

    return {
      className: date.toISOString(),
      style: slotStyle,
      "data-datetime": formattedDate, // 슬롯에 data-datetime 속성 추가
    };
  };

  const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent]);

  useEffect(() => {
    const initialTimeIndicator =
      calendarView !== CalendarViewType.MONTH.type &&
      setTimeout(() => {
        createTimeIndicator();
      }, 200);

    const updateTimeIndicator =
      calendarView !== CalendarViewType.MONTH.type &&
      setInterval(() => {
        const timeTextEl = document.querySelector(`.time-indicator span`);
        if (!timeTextEl) {
          return handleTimeIndicator();
        }
        if (
          timeTextEl &&
          timeTextEl.textContent !==
            localizer.format(new Date(), timeFormat === "24-hour" ? "HH:mm" : "hh:mm a")
        ) {
          return handleTimeIndicator();
        }
      }, 1000);

    return () => {
      clearTimeout(initialTimeIndicator);
      clearInterval(updateTimeIndicator);
    };
  }, [calendarView]);

  useEffect(() => {
    calendarView !== CalendarViewType.MONTH.type &&
      setTimeout(() => {
        const wrapper = document.querySelector(".rbc-time-content");
        const element = document.querySelector(`.rbc-current-time-indicator`);
        if (!wrapper || !element) {
          return;
        }
        const wrapperBound = wrapper.getBoundingClientRect();
        wrapper.scrollTo({
          top: element.offsetTop - wrapperBound.height / 3,
          behavior: "smooth",
        });
      }, 200);

    const resizeTimeIndicator = () => {
      const wrapper = document.querySelector(`.rbc-time-content`);
      const timeEl = document.querySelector(`.time-indicator`);
      if (!wrapper || !timeEl) return;

      const wrapperBound = wrapper.getBoundingClientRect();
      timeEl.style.width = `${wrapperBound.width - 60}px`;
    };

    const calenderWrapper = calendarRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      resizeTimeIndicator();
    });
    resizeObserver.observe(calenderWrapper);

    return () => {
      resizeObserver.unobserve(calenderWrapper);
    };
  }, []);

  const createTimeIndicator = () => {
    const wrapper = document.querySelector(`.rbc-time-content`);
    const wrapperBound = wrapper.getBoundingClientRect();
    const originTimeEl = document.querySelector(`.rbc-current-time-indicator`);

    const newTimeElWrapper = document.querySelector(".rbc-time-gutter");
    const newTimeEl = document.createElement("div");
    newTimeEl.classList.add("time-indicator");
    newTimeEl.style.top = originTimeEl?.style?.top;
    newTimeEl.style.width = `${wrapperBound.width - 60}px`;

    const newTimeTextEl = document.createElement("span");
    newTimeTextEl.textContent = localizer.format(
      new Date(),
      timeFormat === "24-hour" ? "HH:mm" : "hh:mm a"
    );

    newTimeElWrapper.appendChild(newTimeEl);
    newTimeEl.appendChild(newTimeTextEl);
  };
  const timeFormat = useRecoilValue(timeFormatState);
  const handleTimeIndicator = () => {
    const originTimeEl = document.querySelector(`.rbc-current-time-indicator`);
    const timeEl = document.querySelector(`.time-indicator`);
    const timeTextEl = document.querySelector(`.time-indicator span`);
    if (!timeEl) {
      createTimeIndicator();
    }
    timeTextEl.textContent = localizer.format(
      new Date(),
      timeFormat === "24-hour" ? "HH:mm" : "hh:mm a"
    );

    timeEl.style.top = originTimeEl
      ? originTimeEl.style.top
      : `${Number(timeEl.style.top.split("%")[0]) + 0.06945}%`;
  };

  const updateEvent = async (event, start, end, retryCount = 0, oldStart, oldEnd) => {
    if ((event.recurrence && event.recurrence.length > 0) || event.recurringEventId) {
      const updateMobaEventListForMove = (event, oldStart, oldEnd, selectedOption) => {
        // 이전 시작 끝나는 시간 찾기

        if (selectedOption === "cancel") {
          // 취소를 선택한 경우, 원래 시간으로 복원
          setMobaCalendarList((currentList) => {
            return currentList.map((e) => {
              if (e.id === event.id) {
                // 기존 이벤트의 시간을 복원
                return {
                  ...e,
                  start: oldStart,
                  end: oldEnd,
                };
              }
              return e; // 나머지 이벤트는 그대로 유지
            });
          });
        } else {
          handleEventChange(event, oldStart, oldEnd, selectedOption); // 취소 외의 경우 정상적으로 업데이트
        }
      };

      try {
        // 1. RecurringPopup에서 반복 이벤트 옵션 선택

        const recurringType = "edit";
        let isDateEqual = true;

        if (oldStart && oldEnd) {
          isDateEqual =
            areDateEqual(new Date(oldStart), new Date(start)) &&
            areDateEqual(new Date(oldEnd), new Date(end));
        }

        const selectedRecurringOption = await openRecurringPopup(event, recurringType, isDateEqual);

        // 2. 게스트가 있을 경우 GuestPopup에서 알림 여부 선택
        let notifyGuests = false;
        if (event?.attendees && event.attendees.length > 0) {
          notifyGuests = await openGuestPopup(event, "edit");
        }

        // NOTE Client 상태 업데이트
        updateMobaEventListForMove(event, oldStart, oldEnd, selectedRecurringOption);

        updateRecurrenceMutate(
          {
            updatedBlockData: event,
            selectedOption: selectedRecurringOption,
            notify: notifyGuests,
            creator: event.creator,
          },
          {
            onSuccess: () => invalidateCalendarEvents(),
          }
        );
      } catch (error) {
        if (error instanceof UserCancelledPopupError) {
          // 사용자가 팝업을 취소한 경우의 처리
          const isMovedFromAllday = /^(\d{4})-(\d{2})-(\d{2})$/.test(oldStart);

          setMobaCalendarList((currentList) => {
            return currentList.map((e) => {
              if (e.id === event.id) {
                // NOTE 기존 이벤트의 시간을 복원
                return {
                  ...e,
                  allDay: isMovedFromAllday ? true : false,
                  start: isMovedFromAllday
                    ? moment(oldStart).format("YYYY-MM-DD")
                    : new Date(oldStart),
                  startTimeZone: isMovedFromAllday ? null : e.startTimeZone,
                  end: isMovedFromAllday ? moment(oldEnd).format("YYYY-MM-DD") : new Date(oldEnd),
                  endTimeZone: isMovedFromAllday ? null : e.endTimeZone,
                };
              }
              return e; // 나머지 이벤트는 그대로 유지
            });
          });
        } else {
          invalidateCalendarEvents();
          console.error("Event deletion cancelled or failed", error);
        }
      }
    } else {
      const maxRetries = 1;

      // NOTE 반복 없고 게스트 있을 경우 캘린더에서 블록 이동 시 guest popup
      let notifyGuests = false;
      if (event?.attendees && event?.attendees.length > 0) {
        notifyGuests = await openGuestPopup(event, "edit");
      }

      const changedTimeEvent = {
        ...event,
        start: start,
        end: end,
      };

      handleEventChange(changedTimeEvent, oldStart, oldEnd);

      const nullDeletedEvent = deleteNullValueInObject(changedTimeEvent);
      const serverConvertedBlock = convertClientBlockTypeToServerBlockType(nullDeletedEvent);

      api
        .patch(
          `tasks/${event.id}?creator=${event.creator || event.creator}&notification=${notifyGuests}`,
          serverConvertedBlock,
          {
            headers: {
              "X-Requester": event.creator || event.creator,
            },
          }
        )
        .then((res) => {
          const convertEvent = {
            ...event,
            attendees: res.data.task.attendees ? JSON.parse(res.data.task.attendees) : null,
            ...(res.data.task.attendees != null
              ? {
                  // google meet 자동 생성
                  taskType: res.data.task.taskType,
                  hangoutLink: res.data.task.hangoutLink,
                }
              : { hangoutLink: null }),
          };

          // handleGoogleMeetUrlInsertChange(convertEvent);
        })
        .catch((error) => {
          // HTTP 500 에러이고 재시도 횟수가 남았을 때만 재시도
          if (error.response && error.response.status === 500 && retryCount < maxRetries) {
            updateEvent(event, start, end, retryCount + 1); // 재시도
          } else {
            handleEventDelete(event.id);
            loadData(false, false, true);
          }
        });
    }
  };

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      // All day 영역에 drop일 떄
      if (droppedOnAllDaySlot) {
        const oldStartDate = event.start;
        const oldEndDate = event.end;

        // NOTE React Big Calendar에서 가져오는 값이 dateTime이라
        // 혼선을 막기 위해 date로 변경
        const dateFormattedStart = moment(start).format("YYYY-MM-DD");
        const dateFormattedEnd = moment(end).format("YYYY-MM-DD");

        if (!event.allDay) {
          event.allDay = true;
        }
        event.startTimeZone = null;
        event.endTimeZone = null;

        event.start = dateFormattedStart;
        event.end = dateFormattedEnd;

        updateEvent(event, dateFormattedStart, dateFormattedEnd, 0, oldStartDate, oldEndDate);

        trackCall("move_block", {
          previous_start_datetime: formatDateTimeForJune(event.start),
          previous_end_datetime: formatDateTimeForJune(event.end),
          new_start_datetime: formatDateTimeForJune(new Date(event.start).setHours(0, 0, 0, 0)),
          new_end_datetime: formatDateTimeForJune(new Date(event.end).setHours(0, 0, 0, 0)),
          allDay: true,
        });
      } else {
        // 드랍 후 영역이 All day 영역이 아닐 때
        if (event.allDay) {
          // allday에서 allday 아닌 영역으로 이동 시

          // TODO 15분을 사용자 지정 시간으로 변경하기

          trackCall("move_block", {
            previous_start_datetime: formatDateTimeForJune(
              new Date(event.start).setHours(0, 0, 0, 0)
            ),
            previous_end_datetime: formatDateTimeForJune(
              new Date(event.start).setHours(0, 0, 0, 0)
            ),
            new_start_datetime: formatDateTimeForJune(start),
            new_end_datetime: formatDateTimeForJune(
              new Date(new Date(start).setMinutes(new Date(start).getMinutes() + defaultDuration))
            ),
            allDay: false,
          });
        } else {
          // allday 아닌 영역에서 allday 아닌 영역으로 이동
          trackCall("move_block", {
            previous_start_datetime: formatDateTimeForJune(event.start),
            previous_end_datetime: formatDateTimeForJune(event.end),
            new_start_datetime: formatDateTimeForJune(start),
            new_end_datetime: formatDateTimeForJune(end),
            allDay: false,
          });
        }

        const oldStartDate = event.start;
        const oldEndDate = event.end;
        const newStartInMoment = moment(start);

        if (calendarView !== CalendarViewType.MONTH.type && event.allDay) {
          event.allDay = false;
          event.end = newStartInMoment.add(defaultDuration, "minutes").toDate();
        } else {
          event.end = new Date(end);
        }

        event.start = new Date(start);
        event.startTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        event.endTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        updateEvent(event, event.start, event.end, 0, oldStartDate, oldEndDate);
      }
    },
    [calendarView, updateEvent, trackCall, defaultDuration]
  );

  const newEvent = useCallback((event) => {
    updateEvent(event, event.start, event.end);
  }, []);

  const onDropFromOutside = useCallback(
    ({ start, end }) => {
      // endTime === 00:00 인 경우 11:59로 변경
      // 23:45분으로 dnd한 경우 11:59로 변경

      let newEnd;

      const allDay = isAllDayEvent(start, end);
      if (allDay) {
        newEnd = moment(end).format("YYYY-MM-DD");
      } else {
        newEnd = defaultDurationCalculatedEndTime(start, defaultDuration);
      }

      const data = draggedEvent;

      if (draggedEvent == null) return;

      if (draggedEvent === "undroppable") {
        setDraggedEvent(null);
        return;
      }

      if (draggedEvent.dragType === "inbox-calendar-item") {
        const { dragType, kind, ...restData } = data;
        const changedItem = {
          ...restData,
          allDay,
          start: allDay ? moment(start).format("YYYY-MM-DD") : start,
          end: allDay ? moment(newEnd).format("YYYY-MM-DD") : newEnd,
          startTimeZone: allDay ? null : Intl.DateTimeFormat().resolvedOptions().timeZone,
          endTimeZone: allDay ? null : Intl.DateTimeFormat().resolvedOptions().timeZone,
          visibility:
            data.visibility != null
              ? data.visibility
              : defaultVisibility === "public"
                ? "public"
                : "private",
          transparency:
            data.transparency != null
              ? data.transparency
              : defaultVisibility === "invisible"
                ? "transparent"
                : "opaque",
          taskType: data?.taskType ?? "Task",
        };

        updateCalendarBlock({ payload: changedItem });

        const elementsToHide = document.querySelectorAll(".rbc-addons-dnd-drag-row.rbc-row");
        elementsToHide.forEach((element) => {
          element.style.display = "none";
        });

        setDraggedEvent(null);
        return;
      }

      // inprogress task에서 삭제
      updateInboxTaskList((current) => {
        const newData = current.filter((item) => {
          return item.id !== draggedEvent.id;
        });
        return newData;
      });

      // completed task에서 삭제
      updateInboxCompletedTaskList((current) => {
        const newData = current.filter((item) => {
          return item.id !== draggedEvent.id;
        });
        return newData;
      });

      let event = {
        ...data,
        creator: data.creator,
        start: allDay ? moment(start).format("YYYY-MM-DD") : start,
        end: allDay ? moment(newEnd).format("YYYY-MM-DD") : newEnd,
        allDay,
        color: data.color,
        startTimeZone: allDay ? null : Intl.DateTimeFormat().resolvedOptions().timeZone,
        endTimeZone: allDay ? null : Intl.DateTimeFormat().resolvedOptions().timeZone,
        integrationId: data?.integrationId,
        projectId: data.projectId,
        integration: data.integration,
        visibility:
          data.visibility != null
            ? data.visibility
            : defaultVisibility === "public"
              ? "public"
              : "private",
        transparency:
          data.transparency != null
            ? data.transparency
            : defaultVisibility === "invisible"
              ? "transparent"
              : "opaque",
        // integration 드랍 시 taskType 추가
        taskType: data.taskType ?? "Task",
      };

      if (draggedEvent.dragType === "moreTask") {
        const timeDiff = new Date(start).getTime() - new Date(data.start).getTime();
        const newStart = new Date(new Date(data.start).getTime() + timeDiff);
        const newEnd = new Date(new Date(data.end).getTime() + timeDiff);
        const showMoreEvent = {
          ...event,
          start: newStart,
          end: newEnd,
        };

        handleEventChange(showMoreEvent);
        newEvent(showMoreEvent);

        setShowMoreEvents(({ events, date }) => {
          return { date, events: events.filter((item) => item.id !== showMoreEvent.id) };
        });
        setDraggedEvent(null);
        const elementsToHide = document.querySelectorAll(".rbc-addons-dnd-drag-row.rbc-row");
        elementsToHide.forEach((element) => {
          element.style.display = "none";
        });
        return;
      }

      if (data.kind === "gmail" || data.kind === "jira" || data.kind === "slack") {
        const itemId = draggedEvent.integration.itemId;
        event = {
          ...event,
          integration: {
            link: data.link,
            provider: data.kind,
            integrationId: data.integrationId,
            itemId: itemId,
            // NOTE Integration title 프로퍼티 추가됨
            title: event.title,
          },
        };
      }

      setMobaCalendarList((current) => [...current, event]);
      setDraggedEvent(null);

      if (data.kind === "gmail" || data.kind === "jira" || data.kind === "slack") {
        let newItem = {
          id: event.id,
          title: event.title,
          creator: event.creator,
          start: event.startTimeZone
            ? { dateTime: start, timeZone: event.startTimeZone }
            : { date: moment(start).format("YYYY-MM-DD") },
          end: event.endTimeZone
            ? { dateTime: end, timeZone: event.endTimeZone }
            : { date: moment(newEnd).format("YYYY-MM-DD") },
          visibility: event.visibility,
          transparency: event.transparency,
        };

        let dataId = data.id;
        if (dataId.includes("int-")) {
          dataId = dataId.replace("int-", "");
        }

        newItem.integration = {
          integrationId: event.integrationId,
          itemId: dataId,
          provider: event.integration.provider,
          link: event.link,
          title: event.title,
        };

        api
          .post("tasks", newItem, {
            headers: {
              "X-Requester": event.creator,
            },
          })
          .then((res) => {
            trackCall("create_task", {
              location: "integration",
            });

            if (allDay) {
              trackCall("block_time", {
                location: "integration",
                start_datetime: formatDateTimeForJune(new Date(event.start).setHours(0, 0, 0, 0)),
                end_datetime: formatDateTimeForJune(new Date(event.end).setHours(0, 0, 0, 0)),
              });
            } else {
              trackCall("block_time", {
                location: "integration",
                start_datetime: formatDateTimeForJune(event.start),
                end_datetime: formatDateTimeForJune(event.end),
              });
            }

            setToast({
              type: "Success",
              isVisible: true,
              message: "Task planned successfully",
            });

            const convertEvent = {
              ...event,
              // start: startDateParsing.date || new Date(startDateParsing.dateTime),
              // startTimeZone: startDateParsing.timeZone
              //   ? startDateParsing.timeZone
              //   : null,
              // end: endDateParsing.date || new Date(endDateParsing.dateTime),
              // endTimeZone: startDateParsing.timeZone
              //   ? startDateParsing.timeZone
              //   : null,
              attendees: JSON.parse(res.data.task.attendees),
              ...(JSON.parse(res.data.task.attendees).length > 0
                ? {
                    hangoutLink: res.data.task.hangoutLink,
                  }
                : { hangoutLink: null }),
              kind: null,
              visibility: defaultVisibility === "public" ? "public" : "private",
              transparency: defaultVisibility === "invisible" ? "transparent" : "opaque",
              // Integration drop 시
              taskType: "Task",
            };

            handleEventChange(convertEvent, true);
          })
          .catch((error) => {
            loadData(false, false, false);
          });
      } else {
        setToast({
          type: "Success",
          isVisible: true,
          message: "Task planned successfully",
        });
        if (allDay) {
          trackCall("block_time", {
            location: "inbox",
            start_datetime: formatDateTimeForJune(new Date(event.start).setHours(0, 0, 0, 0)),
            end_datetime: formatDateTimeForJune(new Date(event.end).setHours(0, 0, 0, 0)),
          });
        } else {
          trackCall("block_time", {
            location: "inbox",
            start_datetime: formatDateTimeForJune(event.start),
            end_datetime: formatDateTimeForJune(event.end),
          });
        }

        handleEventChange(event);
        newEvent(event);
      }

      const elementsToHide = document.querySelectorAll(".rbc-addons-dnd-drag-row.rbc-row");
      elementsToHide.forEach((element) => {
        element.style.display = "none";
      });
    },
    [draggedEvent, setDraggedEvent, newEvent]
  );

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      if (new Date(start).getTime() === new Date(end).getTime()) {
        const MS15MIN = 900000;
        const endTime = new Date(new Date(end).getTime() + MS15MIN);
        event.start = start;
        event.end = endTime;
        updateEvent(event, start, endTime);
        return;
      }
      trackCall("resize_block", {
        previous_start_datetime: formatDateTimeForJune(event.start),
        previous_end_datetime: formatDateTimeForJune(event.end),
        new_start_datetime: formatDateTimeForJune(start),
        new_end_datetime: formatDateTimeForJune(end),
      });

      // NOTE 기존의 값을 event로 넘겨서 update 취소 시 원래 값으로 되돌아가도록 수정
      updateEvent(event, start, end, 0, event.start, event.end);

      // NOTE 그리고 이후 블록 수정 반영
      event.start = start;
      event.end = end;
    },
    [updateEvent]
  );

  // TODO dev 확인 후 삭제 에정
  // useEffect(() => {
  //   setModalHeight(taskDetailModalHeight);
  // }, [taskDetail.data, taskDetailRef.current, taskDetailModalHeight]);

  const clickEvent = (e, data) => {
    let convertToData = convertClientBlockTypeToServerBlockType(data);
    convertToData = {
      ...convertToData,
      start: data.allDay ? moment(data.start).format("YYYY-MM-DD") : data.start,
      end: data.allDay ? moment(data.end).format("YYYY-MM-DD") : data.end,
    };

    const wrapElement = e.target.closest(".event_wrapper");
    if (!wrapElement) {
      return; // 'wrap' 클래스를 가진 요소가 없으면 함수를 종료합니다.
    }

    const position = calculateModalPosition(wrapElement, null, null);

    setTaskDetail({
      isVisible: true,
      data: convertToData,
      modalPosition: position,
      loadData: loadData,
      handleDataDuplicate: handleDataDuplicate,
      type: "calendar",
      handleEventDelete: handleEventDelete,
      handleEventChange: handleEventChange,
    });
  };
  // TODO Today 여기 고쳐야 됨 ...
  const handleNavigate = (date, view) => {
    // gotoPrev, gotoNext에서 처리해서 해당 함수에서 처리할 부분 없어짐
  };

  const calculateModalPosition = useMemo(() => {
    return (element, bounds, box, isMonthView = false) => {
      if (!element && !bounds && !box) return { x: 0, y: 0 };

      const modalWidth = 362;
      let x, y;

      if (isMonthView && (bounds || box)) {
        x = bounds ? bounds.x : box.x + 5;
        y = bounds ? bounds.y : box.y + 5;

        const clickedElement = document.elementFromPoint(x, y);
        const rbcDayBgElement = clickedElement?.closest(".rbc-day-bg");

        if (rbcDayBgElement) {
          const rect = rbcDayBgElement.getBoundingClientRect();
          if (rect.right + modalWidth > window.innerWidth) {
            x = rect.left - modalWidth - 5;
          } else if (rect.left - modalWidth < 0) {
            x = rect.right + 5;
          } else {
            x = rect.right;
          }
          y = rect.y;
        }
      } else if (element) {
        const rect = element.getBoundingClientRect();
        x = rect.right;
        y = rect.top;

        if (x + modalWidth > window.innerWidth) {
          x = rect.left - modalWidth - 5;
        }
        if (x < 0) {
          x = 48;
        }
      }

      return { x, y };
    };
  }, []);

  // 2. 이벤트 객체 생성 함수 메모이제이션
  const createEventObject = useMemo(() => {
    return (eventId, selectedSpaces) => ({
      id: eventId,
      title: "",
      creator: email,
      isCreateSelectEvent: true,
      visibility: defaultVisibility === "public" ? "public" : "private",
      transparency: defaultVisibility === "invisible" ? "transparent" : "opaque",
      attendees:
        meetWithAccounts.length > 0 ? [{ email, organizer: true }, ...meetWithAccounts] : undefined,
      taskType: meetWithAccounts.length > 0 ? "Event" : "Task",
      ...extractSpaceId(selectedSpaces),
    });
  }, [email, defaultVisibility, meetWithAccounts]);

  const cleanupEventListener = useCallback(() => {
    if (clickListenerRef.current) {
      document.removeEventListener("click", clickListenerRef.current);
      clickListenerRef.current = null;
    }
  }, []);

  const handleSelectSlot = useCallback(
    (slotInfo, selectedSpaces) => {
      if (!account) return;
      if (!slotInfo) return;

      const eventId = uuid4();
      const elements = document.getElementsByClassName(slotInfo.start.toISOString());
      const element = elements[elements.length === 2 ? 1 : 0];

      // 기본 이벤트 속성
      const baseEventProps = createEventObject(eventId, selectedSpaces);

      // All Day 이벤트 처리
      if (!slotInfo.bounds && !slotInfo.box) {
        const newItem = convertGoogleCalendarToMobaCalendar({
          ...baseEventProps,
          allDay: true,
          start: moment(slotInfo?.start).format("YYYY-MM-DD"),
          end: moment(slotInfo?.end).format("YYYY-MM-DD"),
        });

        cleanupEventListener();
        clickListenerRef.current = () => {
          const position = calculateModalPosition(element, null, null);

          setMobaCalendarList((current) => [...current, newItem]);
          setTaskDetail({
            isVisible: true,
            data: newItem,
            modalPosition: position,
            loadData: loadData,
            handleDataDuplicate: handleDataDuplicate,
            type: "calendar",
            handleEventDelete: handleEventDelete,
            handleEventChange: handleEventChange,
          });

          cleanupEventListener();
        };

        document.addEventListener("click", clickListenerRef.current);
        return;
      }

      // 일반 이벤트 처리
      const isMonthView = calendarView === "month";

      // click 시 기본 defaultDuration으로 설정, select(drag)시 slotInfo.end로 설정
      const endDefaultDateTime = moment(slotInfo.start).add(defaultDuration, "minutes").toDate();

      const newItem = isMonthView
        ? convertGoogleCalendarToMobaCalendar({
            ...baseEventProps,
            allDay: true,
            start: moment(slotInfo?.start).format("YYYY-MM-DD"),
            end: moment(slotInfo?.end).format("YYYY-MM-DD"),
          })
        : convertGoogleCalendarToMobaCalendar({
            ...baseEventProps,
            start: { dateTime: slotInfo.start, timeZone: "Asia/Seoul" },
            end: {
              dateTime: slotInfo.action === "click" ? endDefaultDateTime : slotInfo.end,
              timeZone: "Asia/Seoul",
            },
          });

      const position = calculateModalPosition(element, slotInfo.bounds, slotInfo.box, isMonthView);

      const rect = element?.getBoundingClientRect() || null;

      setMobaCalendarList((current) => [...current, newItem]);

      if (meetWithAccounts.length > 0) {
        mutateMeetingCode(email);
      }

      setTaskDetail({
        isVisible: true,
        data: newItem,
        modalPosition: position,
        loadData: loadData,
        handleDataDuplicate: handleDataDuplicate,
        type: "calendar",
        handleEventDelete: handleEventDelete,
        handleEventChange: handleEventChange,
      });
    },
    [
      account,
      createEventObject,
      calculateModalPosition,
      cleanupEventListener,
      calendarView,
      defaultVisibility,
      meetWithAccounts,
      setMobaCalendarList,
      defaultDuration,
      mutateMeetingCode,
    ]
  );

  const isDragging = useRef();

  const handleDragResizeEvent = (e) => {
    if (!isDragging.current) {
      return;
    }
    const scrollEl = document.querySelector(".rbc-time-content");
    const scrollBound = scrollEl.getBoundingClientRect();
    if (e.pageY > scrollBound.bottom - 16) {
      scrollEl.scrollTop += 1;
    }
  };

  const handleClickShowMore = (e) => {
    const rect = e.target.getBoundingClientRect();
    const row = e.target.closest(".rbc-month-row").getBoundingClientRect();
    showMorePopupPos.current = {
      top: row.top - 10,
      left: rect.left + (rect.width + 10) / 2,
      width: rect.width + 30,
    };
    currentMoreTriggerRef.current = e.target;
    setShowMorePopup(!showMorePopup);
  };

  const handleShowMorePopupPos = (eventCount) => {
    const eventBlockHeight = 24;
    const showMorePopupPadding = 52;
    const popupHeight = eventBlockHeight * eventCount + 2 * eventCount + showMorePopupPadding;
    const popupWidth = showMorePopupPos.current.width;

    const newPos = {
      top: showMorePopupPos.current.top,
      left: showMorePopupPos.current.left,
      width: showMorePopupPos.current.width,
    };

    // width가 넘친 경우
    if (showMorePopupPos.current.left + popupWidth > window.innerWidth) {
      newPos.left = window.innerWidth - popupWidth - 10;
    }

    // height가 넘친 경우
    if (showMorePopupPos.current.top + popupHeight > window.innerHeight) {
      newPos.top = "auto";
      newPos.bottom = 0;
    }
    showMorePopupPos.current = newPos;
  };

  useEffect(() => {
    const handleMouseUp = () => {
      setDraggedEvent(null);
      const targetEl = document.querySelector(".rbc-calendar");
      if (targetEl) {
        targetEl.classList.remove("rbc-addons-dnd-is-dragging");
      }
    };
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  // NOTE Show More Popup에 전달되는 이벤트 값이 변경되면 동기화되도록 설정
  const synchronizedShowMoreBlocks = useMemo(() => {
    if (!showMoreEvents) return null;

    return {
      ...showMoreEvents,
      events: showMoreEvents.events.map(
        (event) => myEvents.find((e) => e.id === event.id) || event
      ),
    };
  }, [showMoreEvents, myEvents]);

  return (
    <div
      ref={calendarRef}
      style={{ width: "100%", height: "100%" }}
      onMouseDown={() => {
        isDragging.current = true;
      }}
      onMouseUp={() => {
        isDragging.current = false;
      }}
    >
      {myEvents && (
        <DragAndDropCalendar
          defaultDate={new Date()}
          components={{
            toolbar: CalendarHeader,
            event: (props) => (
              <CustomEvent
                {...props}
                localizer={localizer}
                loadData={loadData}
                onEventChange={handleEventChange}
              />
            ),
            eventWrapper: (props) => (
              <CustomEventWrapper
                {...props}
                onClick={(e, data, rect) => {
                  !data.isMeetWith && clickEvent(e, data, rect);
                }}
                loadData={loadData}
                onEventDelete={handleEventDelete}
                onDataDuplicate={handleDataDuplicate}
                onEventChange={handleEventChange}
                setMobaCalendarList={setMobaCalendarList}
              />
            ),

            timeGutterHeader: MyTimeGutterHeader,
            timeGutterWrapper: (props) => <MyTimeGutterWrapper {...props} localizer={localizer} />,
            day: {
              header: MyDayHeader,
            },
            week: {
              header: MyDayWeekHeader,
            },
            day3: {
              header: MyDayWeekHeader,
            },
          }}
          defaultView={
            Object.values(CalendarViewType).find(
              (viewType) => viewType.type === loadFromLocalStorage("calendarViewType")
            )?.type ?? Views.DAY
          }
          localizer={localizer}
          views={views}
          formats={CustomFormat}
          dragFromOutsideItem={dragFromOutsideItem}
          eventPropGetter={eventPropGetter}
          dayPropGetter={(date) => {
            const dayofWeek = localizer.format(new Date(date), "ddd");
            if (dayofWeek === "Sun" || dayofWeek === "Sat") {
              return { className: "weekend" };
            }
          }}
          slotPropGetter={slotPropGetter}
          events={[...myEvents, ...meetWithEvents]}
          onDropFromOutside={onDropFromOutside}
          onDragOver={customOnDragOver}
          onEventDrop={moveEvent}
          onDragStart={(event) => {
            event.event.taskType === "Task" &&
              setDraggedEvent({ ...event.event, dragType: "calendarTask" });

            if (event.action === "resize" && event.direction === "DOWN") {
              document.addEventListener("mousemove", handleDragResizeEvent);
            }
          }}
          onEventResize={resizeEvent}
          onNavigate={handleNavigate}
          onSelectSlot={(e) => handleSelectSlot(e, inProgressSelectedSpaceIdList)}
          resizable
          onView={handleView}
          showMultiDayTimes={true}
          selectable={!taskDetail.isVisible}
          step={15}
          timeslots={4}
          dayLayoutAlgorithm={(params) => {
            return overlap({ ...params, minimumStartDifference: 15 });
          }}
          tooltipAccessor={null}
          messages={{
            showMore: (total) => (
              <ShowMoreTrigger ref={triggerRef} onClick={handleClickShowMore}>
                + {total} more
              </ShowMoreTrigger>
            ),
          }}
          popup={false}
          showAllEvents={false}
          doShowMoreDrillDown={false}
          onShowMore={(events, date) => {
            handleShowMorePopupPos(events.length);
            setShowMoreEvents({ events, date });
          }}
          draggableAccessor={(event) => !event.isMeetWith}
        />
      )}
      {showMorePopup &&
        createPortal(
          <ShowMorePopup
            ref={targetRef}
            date={synchronizedShowMoreBlocks.date}
            events={synchronizedShowMoreBlocks.events}
            onClose={() => setShowMorePopup(false)}
            style={showMorePopupPos.current}
          >
            {(event) => (
              <CustomEventWrapper
                key={event.id}
                event={event}
                onClick={(e, data, rect) => {
                  if (data.isMeetWith) return;

                  clickEvent(e, data, rect);
                }}
                loadData={loadData}
                onEventDelete={handleEventDelete}
                onDataDuplicate={handleDataDuplicate}
                onEventChange={handleEventChange}
              >
                <CustomEvent
                  event={event}
                  localizer={localizer}
                  loadData={loadData}
                  onEventChange={handleEventChange}
                />
              </CustomEventWrapper>
            )}
          </ShowMorePopup>,
          document.body
        )}
    </div>
  );
};
const DragAndDropCalendar = withDragAndDrop(Calendar);

const CustomFormat = {
  timeGutterFormat: (date, culture, localizer) => localizer.format(date, "HH", culture),
  eventTimeRangeFormat: () => "",
  dayFormat: (date, culture, localizer) => localizer.format(date, "D ddd", culture),
  dateFormat: (date, culture, localizer) => localizer.format(date, "D", culture),
};

import { UseMutationOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import { CalendarBlockType, InboxBlockType, isCalendarBlock } from "../../types/block/BlockType";
import { AxiosError } from "axios";
import { useSetRecoilState } from "recoil";
import { mobaCalendarListState } from "../../recoil/calendar/mobaCalendarListState";
import { inboxTaskListState } from "../../recoil/taskList/inboxTaskListState";
import useApi from "../../services/auth/useApi";
import { useJuneTrackCall } from "../../utils/june/analytics";
import { toastState } from "../../recoil/toast/toastState";
import { TaskDividerType } from "../../services/divider/divider.service";
import { inboxBlockQueryKey } from "../inbox/inboxQueryKey";
import { doneTaskListState } from "../../recoil/taskList/doneTaskListState";
import { updateClientBlockList } from "../../utils/common/block/updateClientBlockList";
import { calendarEventsKey } from "../../hooks/useFetchCalendarEvents";

interface ContextType {
  previousBlocks: CalendarBlockType[] | (InboxBlockType | TaskDividerType)[] | undefined;
}

// NOTE itemStatus가 바뀐 값을 전달 받아 그대로 payload로 사용
export const updateBlockStatusApi = async (
  api: ReturnType<typeof useApi>,
  payload: CalendarBlockType | InboxBlockType
): Promise<void> => {
  await api.patch(
    "tasks/" + payload.id + "/" + (payload.itemStatus === "Completed" ? "done" : "undone"),
    "",
    {
      headers: { "X-Requester": payload.creator },
    }
  );
};

export const useUpdateBlockStatusMutation = (
  options?: UseMutationOptions<void, AxiosError, CalendarBlockType | InboxBlockType, ContextType>
) => {
  const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);
  const trackCall = useJuneTrackCall();
  const api = useApi();
  const queryClient = useQueryClient();
  const setInboxTaskList = useSetRecoilState(inboxTaskListState);
  const setToast = useSetRecoilState(toastState);
  const setDoneTaskList = useSetRecoilState(doneTaskListState);

  return useMutation<void, AxiosError, CalendarBlockType | InboxBlockType, ContextType>({
    mutationFn: (payload) => updateBlockStatusApi(api, payload),

    onMutate: async (payload) => {
      payload.itemStatus === "Completed" &&
        setToast({
          type: "Done",
          isVisible: true,
          message: "Task marked as done",
        });

      if ("start" in payload) {
        const previousBlocks = queryClient.getQueryData<CalendarBlockType[]>(calendarEventsKey.all);
        setMobaCalendarList((prev) => updateClientBlockList(prev, payload, { prepend: true }));
        return { previousBlocks };
      }

      const previousBlocks =
        queryClient.getQueryData<(InboxBlockType | TaskDividerType)[]>(inboxBlockQueryKey());

      // Done 태스크 리스트 업데이트
      setDoneTaskList((current) => {
        if (payload.itemStatus === "Completed") {
          return updateClientBlockList(current, payload, { prepend: true });
        } else {
          return updateClientBlockList(current, payload, { removeItem: true });
        }
      });

      // Inbox 태스크 리스트 업데이트
      setInboxTaskList((prev) => {
        if (payload.itemStatus === "Completed") {
          return updateClientBlockList(prev, payload, { removeItem: true });
        } else if (payload.itemStatus === "InProgress") {
          return updateClientBlockList(prev, payload, { prepend: true });
        }
        return prev;
      });

      return { previousBlocks };
    },
    onSuccess: (_, data) => {
      const currentActiveTab = localStorage.getItem("activeTab") ?? "Inbox";

      if (data.itemStatus === "Completed")
        return trackCall("done_block", {
          location: currentActiveTab === "Inbox" ? "inbox" : "today_inbox",
        });

      return trackCall("undone_block", {
        location: currentActiveTab === "Inbox" ? "inbox" : "today_inbox",
      });
    },
    onError: (error, variables, context) => {
      if (context?.previousBlocks) {
        if (isCalendarBlock(variables)) {
          setMobaCalendarList(context.previousBlocks as CalendarBlockType[]);
        } else {
          if (!context.previousBlocks) setInboxTaskList([]);
          setInboxTaskList(context.previousBlocks as (InboxBlockType | TaskDividerType)[]);
        }
      }
    },
    ...options,
  });
};

import moment from "moment";

export const isAllDayEvent = (start, end) => {
  const isStartMidnight = moment(start).format("HH:mm") === "00:00";
  const isEndMidnight = moment(end).format("HH:mm") === "00:00";
  const dayDifference = moment(end).diff(moment(start), "days");

  return isStartMidnight && isEndMidnight && dayDifference === 1;
};

export const defaultDurationCalculatedEndTime = (start, defaultDuration) => {
  const MS1MIN = 60000; // 1분(ms)

  // 먼저 duration을 더한 시간 계산
  const calculatedEnd = moment(start).add(defaultDuration, "minutes");
  // 계산된 종료 시간이 00:00인지 체크
  const isEndMidnight = calculatedEnd.format("HH:mm") === "00:00";

  // 예외 : end를 defaultDuration으로 반영 후 종료시간이 자정(00:00)이 되는 경우
  // -> 이벤트가 두개로 생성되는걸 방지하기 위해 종료 시간 1분 빼기, 아니면 그대로 반환
  return isEndMidnight ? new Date(calculatedEnd.valueOf() - MS1MIN) : calculatedEnd.toDate();
};

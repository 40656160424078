import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import useApi from "../../../services/auth/useApi";
import styles from "./TrashPopup.module.css";
import trashDeleteImg from "../../../assets/Trash/trash-delete.png";
import { useJuneTrackCall } from "../../../utils/june/analytics";
import { COMPLETED } from "../../../constants/taskStateType";
import useFetchCompletedTasks from "../../../hooks/useFetchCompletedTasks";
import useFetchInProgressBlockQuery from "../../../react-query/inbox/core/useFetchInProgressBlockQuery";
import { deletedTaskListState } from "../../../recoil/taskList/deletedTaskListState";
import { trashRestoreCalendarEventState } from "../../../recoil/trash/trashRestoreCalendarEventState";
import formatDateTimeForJune from "../../../utils/june/formatDateTimeForJune";
import { useDeleteSpaceMutation } from "../../../react-query/space/deletion/useDeleteSpaceMutation";
import { useRestoreTrashedSpaceMutation } from "../../../react-query/space/deletion/useRestoreTrashedSpaceMutation";
import { TrashTaskRow } from "./TrashTaskRow";
import { TrashProjectRow } from "./TrashProjectRow";
import useFetchDeletedTasks from "../../../hooks/useFetchDeletedTasks";
import { useDeleteSpacesMutation } from "../../../react-query/space/deletion/useDeleteSpacesMutation";
import { useFetchSpaceQuery } from "../../../react-query/space/core/useFetchSpaceQuery";
import { getDeletedSpaceList } from "../../../services/space/space.service";
import { useQueryClient } from "@tanstack/react-query";
import { inboxBlockQueryKey } from "../../../react-query/inbox/inboxQueryKey";

export default function TrashPopup({ onClose }) {
  const api = useApi();
  const { fetchDeletedTasks } = useFetchDeletedTasks();

  /* space */
  const { data: allSpaces = [] } = useFetchSpaceQuery();
  const trashedSpaces = getDeletedSpaceList(allSpaces);
  const { mutate: deleteSpace } = useDeleteSpaceMutation();
  const { mutate: restoreSpace } = useRestoreTrashedSpaceMutation();
  const { mutate: deleteSpaces } = useDeleteSpacesMutation();

  const [activeIndex, setActiveIndex] = useState(0);
  const [tabContArr, setContArr] = useState([]);
  const tabNameList = ["Spaces", "Tasks"];

  const [deletedTaskList, updateDeletedTaskList] = useRecoilState(deletedTaskListState);
  const [trashRestoreCalendarEvent, setTrashRestoreCalendarEventState] = useRecoilState(
    trashRestoreCalendarEventState
  );
  const { fetchCompletedTasks } = useFetchCompletedTasks();

  const queryClient = useQueryClient();
  const trackCall = useJuneTrackCall();

  const tabClickHandler = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    fetchDeletedTasks();
  }, []);

  const taskLoadData = () => {
    setContArr(deletedTaskList);
  };

  useEffect(() => {
    if (activeIndex === 0) {
      trackCall("view_trash", { type: "space" });
    } else {
      trackCall("view_trash", { type: "task" });
    }
  }, [activeIndex, trackCall]);

  useEffect(() => {
    if (activeIndex === 1) {
      taskLoadData();
    }
  }, [activeIndex, taskLoadData]);

  const handleRestoreSpace = (project) => {
    restoreSpace(project.id);
  };
  const handleDeleteSpace = (projectId) => {
    deleteSpace(projectId);
  };

  const handelTaskRestoreOnClick = (task, creator) => {
    const newTask = { ...task, itemStatus: task.lastStatus };
    api
      .patch("tasks/" + task.id + "/unmark", "", {
        headers: {
          "X-Requester": creator,
        },
      })
      .then(() => {
        if (task.start) {
          setTrashRestoreCalendarEventState(newTask);
          trackCall("block_time", {
            location: "trash",
            start_datetime: formatDateTimeForJune(newTask.start?.dateTime),
            end_datetime: formatDateTimeForJune(newTask.end?.dateTime),
          });
        } else if (!task.start && task.lastStatus === COMPLETED) {
          fetchCompletedTasks();
        } else {
          const handleInvalidateInboxList = () => {
            queryClient.invalidateQueries({
              queryKey: inboxBlockQueryKey(),
            });
          };
          handleInvalidateInboxList();
        }

        trackCall("restore_block", {
          type: "deleted_item",
        });

        fetchDeletedTasks();
      });
  };

  const handleTaskDeleteClick = (taskId, creator) => {
    api
      .delete("tasks/" + taskId, {
        headers: {
          "X-Requester": creator,
        },
      })
      .then(() => {
        fetchDeletedTasks();
      });
  };

  const handleEmptySpace = () => {
    const spaceIds = trashedSpaces?.map((space) => space.id);
    if (spaceIds && spaceIds.length > 0) {
      deleteSpaces(spaceIds);
    }
  };

  const handleTaskEmpty = () => {
    api
      .delete("/tasks", {
        data: {
          tasks: tabContArr.map((task) => ({
            taskId: task.id,
            creator: task.creator,
          })),
        },
      })
      .then(() => {
        fetchDeletedTasks();
      });
  };

  const handleEmpty = () => {
    if (activeIndex === 0) {
      handleEmptySpace();
    } else {
      handleTaskEmpty();
    }
  };

  return (
    <div className={styles.trash}>
      <ul>
        {tabNameList.map((tabName, index) => {
          return (
            <li
              className={`${activeIndex === index ? styles.tap__active : styles.tap__inactive}`}
              onClick={() => tabClickHandler(index)}
            >
              {tabName}
            </li>
          );
        })}
      </ul>
      <div className={styles.tap__contents}>
        {activeIndex === 0
          ? trashedSpaces?.map((project, index) => (
              <TrashProjectRow
                key={index}
                index={index}
                data={project}
                onDeleteClick={handleDeleteSpace}
                onRestoreClick={handleRestoreSpace}
              />
            ))
          : tabContArr?.map((task, index) => (
              <TrashTaskRow
                key={index}
                index={index}
                data={task}
                onDeleteClick={handleTaskDeleteClick}
                onRestoreClick={handelTaskRestoreOnClick}
              />
            ))}
      </div>
      <div className={styles.trash__guide}>
        <span className={styles.trash__guide__text}>
          Your history is available here for 30 days.
        </span>
        <div className={styles.trash__guide__empty} onClick={handleEmpty}>
          <img className={styles.project__delete} src={trashDeleteImg} />
          <span className={styles.trash__guide__empty__text}>Empty</span>
        </div>
      </div>
    </div>
  );
}

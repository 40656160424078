import { areDateTimeEqual } from "../../common/dateTime/areDateTimeEqual";

// NOTE note 제외한 나머지 데이터 비교
const parseDate = (date) => {
  return date ? new Date(date) : null;
};

const normalizeValue = (value) => (value === undefined || value === null ? "" : value);
const normalizeArray = (value) => (value === undefined || value === null ? [] : value);

export const isTaskDataChanged = (initialData, latestData) => {
  const initialStart = parseDate(initialData.start);
  const latestStart = parseDate(latestData.start);
  const initialEnd = parseDate(initialData.end);
  const latestEnd = parseDate(latestData.end);

  return (
    normalizeValue(initialData.title) !== normalizeValue(latestData.title) ||
    (initialStart && latestStart
      ? !areDateTimeEqual(initialStart, latestStart)
      : initialStart !== latestStart) ||
    (initialEnd && latestEnd
      ? !areDateTimeEqual(initialEnd, latestEnd)
      : initialEnd !== latestEnd) ||
    JSON.stringify(normalizeArray(initialData.links)) !==
      JSON.stringify(normalizeArray(latestData.links)) ||
    normalizeValue(initialData.projectId) !== normalizeValue(latestData.projectId) ||
    (!initialData.isCreateSelectEvent &&
      JSON.stringify(normalizeArray(initialData.attendees)) !==
        JSON.stringify(normalizeArray(latestData.attendees))) ||
    JSON.stringify(normalizeArray(initialData.recurrence)) !==
      JSON.stringify(normalizeArray(latestData.recurrence)) ||
    normalizeValue(initialData.visibility) !== normalizeValue(latestData.visibility) ||
    normalizeValue(initialData.transparency) !== normalizeValue(latestData.transparency)
  );
};
